<template>
    <div class="landing_tab">
        <div class="data_picker_group">
            <div class="date_picker">
                <span>最近发现时间：</span>
                <span class="picker" :class="closeTabIndex == 0?'active':''" @click="changeCloseTabIndex(0)">全部</span>
                <span class="picker" :class="closeTabIndex == 1?'active':''" @click="changeCloseTabIndex(1)">近1天</span>
                <span class="picker" :class="closeTabIndex == 2?'active':''" @click="changeCloseTabIndex(2)">近7天</span>
                <span class="picker" :class="closeTabIndex == 3?'active':''" @click="changeCloseTabIndex(3)">近30天</span>
                <span :style="{'color': datePicker1.length == 0?'#333':'#1f81f8'}">自定义：
                    <el-date-picker
                        style="margin-left: 10px; width: 215px"
                        size="mini"
                        v-model="datePicker1"
                        @change="changeCloseDate"
                        type="daterange"
                        value-format="yyyy-MM-dd"
                        range-separator="至"
                        start-placeholder="开始日期"
                        end-placeholder="结束日期">
                    </el-date-picker>
                </span>
            </div>
            <div class="date_picker" style="margin-top: 14px">
                <span>最早发现时间：</span>
                <span class="picker" :class="earlyTabIndex == 0?'active':''" @click="changeEarlyTabIndex(0)">全部</span>
                <span class="picker" :class="earlyTabIndex == 1?'active':''" @click="changeEarlyTabIndex(1)">近1天</span>
                <span class="picker" :class="earlyTabIndex == 2?'active':''" @click="changeEarlyTabIndex(2)">近7天</span>
                <span class="picker" :class="earlyTabIndex == 3?'active':''" @click="changeEarlyTabIndex(3)">近30天</span>
                <span :style="{'color': datePicker2.length == 0?'#333':'#1f81f8'}">自定义：
                    <el-date-picker
                        style="margin-left: 10px; width: 215px"
                        size="mini"
                        v-model="datePicker2"
                        @change="changeEarlyDate"
                        type="daterange"
                        value-format="yyyy-MM-dd"
                        range-separator="至"
                        start-placeholder="开始日期"
                        end-placeholder="结束日期">
                    </el-date-picker>
                </span>
            </div>
        </div>
        <div class="info">
            <span class="right">共找到<span>{{pageConfig.real_cnt >= 100000?'100000+':pageConfig.real_cnt}}</span>个推广落地页</span>
            <myButton type="default" width="100" height="40" @click="exportList">
                <img slot="img" src="@/assets/img/companyDetails/icon_export.png" alt="" width="14px" height="14px">
                <span slot="text">立即导出</span>
            </myButton>
        </div>
        <el-table
            border
            :data="tableData"
            v-loading="loading"
            empty-text="暂无相关数据"
            @sort-change="sortChange"
            :header-cell-style="{background:'#f8fafc', color: '#666666'}">
            <el-table-column
                width="80"
                align="center"
                label="序号">
                <template slot-scope="scope">
                    <div style="text-align: center">{{scope.row.index}}</div>
                </template>
            </el-table-column>
            <el-table-column
                min-width="100"
                label="推广落地页链接">
                <template slot-scope="scope">
                    <a class="five_line" :href="scope.row.land_page_url" target="_blank">{{scope.row.land_page_url}}</a>
                </template>
            </el-table-column>
            <el-table-column
                align="center"
                sortable="custom"
                min-width="70"
                prop="record_count"
                label="推广记录数">
                <template slot-scope="scope">
                    <span>{{scope.row.record_count || '数据更新中'}}</span>
                </template>
            </el-table-column>
            <el-table-column
                align="center"
                sortable="custom"
                min-width="60"
                prop="promotion_days"
                label="在线天数">
                <template slot-scope="scope">
                    <span>{{scope.row.promotion_days || '数据更新中'}}</span>
                </template>
            </el-table-column>
            <el-table-column
                align="center"
                sortable="custom"
                min-width="80"
                prop="ad_time_from"
                label="最早发现时间">
                <template slot-scope="scope">
                    <span>{{scope.row.ad_time_from || '暂无数据'}}</span>
                </template>
            </el-table-column>
            <el-table-column
                align="center"
                sortable="custom"
                min-width="80"
                prop="ad_time_to"
                label="最近发现时间">
                <template slot-scope="scope">
                    <span>{{scope.row.ad_time_to || '暂无数据'}}</span>
                </template>
            </el-table-column>
            <el-table-column
                min-width="150"
                label="最近推广记录">
                <template slot-scope="scope">
                    <div class="record">
                        <div class="key_word" :title="scope.row.ad_word">
                            <img class="search" src="@/assets/img/h5/search_h5.png"/>
                            <span class="right">{{scope.row.ad_word}}</span>
                        </div>
                        <div class="cell_title" :title="scope.row.title" @click="$C.jump(scope.row.land_url, 3187)" v-html="scope.row.title"></div>
                        <div class="cell_text" :title="scope.row.content">{{scope.row.content}}</div>
                        <div class="cell_link" v-if="scope.row.domain">
                            <span class="link">{{scope.row.domain}}</span>
                            <span class="platform">{{$C.platformTurn(scope.row.platform)}}</span>
                        </div>
                    </div>
                </template>
            </el-table-column>
            <template #empty>
                <no-data>
                    <img slot="img" src="../../../assets/img/compare/empty.png" style="width: 187px; height: 144px"/>
                    <span slot="text" style="line-height: 14px">暂无推广落地页</span>
                </no-data>
            </template>
        </el-table>
        <page :config="pageConfig" @pageChange="pageChange" @limitChange="limitChange"></page>


        <export_dialog
            @beforeClose="dialogVisible = false"
            @handleExport="handleExport"
            :visible.sync="dialogVisible"
            :all-cnt="parseInt(exportCnt)"
            :surplus="surplus"
            :fast-limit="2500"
            :total-limit="10000"
            width="60%">
        </export_dialog>

        <common_dialog
            @beforeClose="notVipShow = false"
            :visible.sync="notVipShow"
            :text="commonDialogText"
            width="40%">
        </common_dialog>
    </div>
</template>

<script>
  import * as api from "@/api/companyDetails"
  import myButton from "../../../components/myButton/myButton";
  import PinganAna from "pingansec-vue-ana";
  import common_dialog from "../../../components/dialogs/common_dialog";
  import export_dialog from "../../../components/dialogs/export_dialog";
  export default {
    name: "landingTab",
    components: {
      myButton,
      common_dialog,
      export_dialog,
    },
    data() {
      return {
        tableData: [],
        closeTabIndex: 0,
        datePicker1: [],
        earlyTabIndex: 0,
        datePicker2: [],
        loading: false,
        sort_key: '',
        sort_type: '',
        pageConfig: {
          page: 1,
          limit: 10,
          total: null,
          sizes: [10, 20, 30, 40, 50],
          count: 5,
          real_cnt: null
        },
        notVipShow: false, //非vip导出提示弹窗
        commonDialogText: '', //弹窗内容
        dialogVisible: false, //导出列表确认弹窗
        surplus: null, //剩余导出次数
        exportCnt: null, //导出数量
      }
    },
    mounted() {
    },
    updated() {
      var dom = document.getElementsByClassName('cell_link')
      if(dom.length) {
        setTimeout(_=>{
          var width = (dom[0].clientWidth - 65) + 'px'
          for (var i = 0; i < dom.length; i++) {
            dom[i].children[0].style.setProperty('--max-width',width)
          }
        },100)
      }
    },
    computed: {
      companyDigest() {
        return this.$route.query.digest;
      },
      closeDatePicker() {
        let date = {
          b_time: '',
          e_time: ''
        };
        switch (this.closeTabIndex) {
          case 1:
            date.b_time = this.$C.getBeforeDay(0);
            date.e_time = this.$C.getBeforeDay(0);
            break;
          case 2:
            date.b_time = this.$C.getBeforeDay(7);
            date.e_time = this.$C.getBeforeDay(0);
            break;
          case 3:
            date.b_time = this.$C.getBeforeDay(30);
            date.e_time = this.$C.getBeforeDay(0);
            break;
          case 4:
            date.b_time = this.datePicker1[0];
            date.e_time = this.datePicker1[1];
            break;
          default:
            date.b_time = ''
            date.e_time = ''
        };
        return date;
      },
      earlyDatePicker() {
        let date = {
          b_time: '',
          e_time: ''
        };
        switch (this.earlyTabIndex) {
          case 1:
            date.b_time = this.$C.getBeforeDay(0);
            date.e_time = this.$C.getBeforeDay(0);
            break;
          case 2:
            date.b_time = this.$C.getBeforeDay(7);
            date.e_time = this.$C.getBeforeDay(0);
            break;
          case 3:
            date.b_time = this.$C.getBeforeDay(30);
            date.e_time = this.$C.getBeforeDay(0);
            break;
          case 4:
            date.b_time = this.datePicker2[0];
            date.e_time = this.datePicker2[1];
            break;
          default:
            date.b_time = ''
            date.e_time = ''
        };
        return date;
      }
    },
    methods: {
      exportList() {
        if (this.$store.state.userInfo.vip_type == 10) {
          this.notVipShow = true;
          this.commonDialogText = '您当前为试用版会员，标准版会员可导出数据，请联系您的客户经理开通'
        } else if(this.$store.state.userInfo.vip_type == 5) {
          this.notVipShow = true;
          this.commonDialogText = '当前为基础版会员，标准版会员可导出数据，请联系在线客服开通';
        } else {
          api.get_last_export_cnt().then(res => {
            if(res.data.result_code==0) {
              this.surplus = res.data.data.total-res.data.data.used;
              this.exportCnt = this.pageConfig.real_cnt
              this.dialogVisible = true
            } else {
              this.$message({
                type: 'error',
                message: res.data.message
              })
            }
          })
        }
      },
      handleExport(count) {
        let condition = {
          digest: this.companyDigest,
          latest_started: this.closeDatePicker.b_time,
          latest_stoped: this.closeDatePicker.e_time,
          first_started: this.earlyDatePicker.b_time,
          first_stoped: this.earlyDatePicker.e_time,
          sort_key: this.sort_key,
          sort_type: this.sort_type,
          page: this.pageConfig.page,
          limit: this.pageConfig.limit
        };
        let params = {
          export_type: '10',
          export_limit: count,
          export_condition: JSON.stringify(condition)
        }
        this.dialogVisible = false
        api.ge_export_task(params).then(res => {
          if(res.data.result_code==0) {
            this.$message({
              type: 'success',
              message: '导出任务创建成功！'
            })
            this.dialogVisible = false;
            const { href } = this.$router.resolve({
              name: "export",
            });
            setTimeout(() => {
              window.open(href, '_blank');
            },500)
          } else {
            this.$message({
              type: 'error',
              message: res.data.message
            })
          }
        })
      },
      sortChange(column) {
        this.sort_key = column.prop
        if(column.order == 'ascending') {
          this.sort_type = 'asc'
        } else if (column.order == 'descending') {
          this.sort_type = 'desc'
        } else {
          this.sort_type = ''
        }
        this.init();
      },
      pageChange(val) {
        this.pageConfig.page = val
        this.init();
      },
      limitChange(val) {
        this.pageConfig.page = 1;
        this.pageConfig.limit = val;
        this.init();
      },
      changeCloseTabIndex(index) {
        this.closeTabIndex = index;
        if (index == 0) {
          this.datePicker1 = []
        }
        this.init()
      },
      changeEarlyTabIndex(index) {
        this.earlyTabIndex = index;
        if (index == 0) {
          this.datePicker2 = []
        }
        this.init()
      },
      changeCloseDate() {
        this.closeTabIndex = 4;
        this.init()
      },
      changeEarlyDate() {
        this.earlyTabIndex = 4;
        this.init()
      },
      addIndex(list,config) {
        for(var i = 0;i < list.length;i++) {
          list[i].index = (config.page-1)*config.limit+i+1
        }
        return list
      },
      init() {
        let params = {
          digest: this.companyDigest,
          latest_started: this.closeDatePicker.b_time,
          latest_stoped: this.closeDatePicker.e_time,
          first_started: this.earlyDatePicker.b_time,
          first_stoped: this.earlyDatePicker.e_time,
          sort_key: this.sort_key,
          sort_type: this.sort_type,
          page: this.pageConfig.page,
          limit: this.pageConfig.limit
        }
        this.loading = true
        let p = new Promise((resolve, reject) => {
          api.get_landing_list(params).then(res => {
            if(res.data.result_code == 0) {
              this.tableData = this.addIndex(res.data.data.rows, this.pageConfig);
              this.pageConfig.real_cnt = res.data.data.real_cnt
              this.pageConfig.total = parseInt(res.data.data.cnt)
              this.loading = false
              resolve(res.data.data);
            } else {
              this.$message({
                type: 'error',
                message: res.data.message
              })
            }
          })
        })
        return p;
      }
    }
  }
</script>

<style scoped lang="scss">
.landing_tab {
    .five_line {
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 4;
        overflow: hidden;
    }
   .data_picker_group {
       padding: 16px;
       border: solid 1px #ebeff2;
       .date_picker {
           font-size: 14px;
           color: #999999;
           .picker {
               color: #333;
               padding: 3.5px 13px;
               margin: 10px;
               cursor: pointer;
               box-sizing: border-box;
           }
           .active {
               color: #1f81f8;
               border: 1px solid #1f81f8;
               border-radius: 5px;
           }
       }
   }
   .info {
       display: flex;
       align-items: center;
       justify-content: space-between;
       margin-top: 24px;
       margin-bottom: 27px;
       font-size: 14px;
       color: #999999;
       .right {
           span {
               color: #ef3819;
           }
       }
   }
    .record {
        .key_word {
            max-width: 100%;
            background-color: #ffffff;
            border: solid 1px #e4e4e4;
            display: inline-flex;
            align-items: center;
            padding: 1px 10px;
            border-radius: 3px;
            font-size: 14px;
            overflow-y: hidden;
            margin-bottom: 9px;
            .right {
                margin-left: 6px;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
            }
            .search {
                width: 13px;
                height: 13px;
            }
        }
        .cell_title {
            font-size: 16px;
            color: #1f81f8;
            cursor: pointer;
            text-decoration: underline #1f81f8;
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;
        }
        .cell_text {
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 2;
            overflow: hidden;
        }
        .cell_link {
            height: 25px;
            margin-top: 9px;
            font-size: 14px;
            color: #999;
            white-space: nowrap;
            .link {
                display: inline-block;
                overflow: hidden;
                text-overflow: ellipsis;
                max-width: var(--max-width);
            }
            .platform {
                font-size: 12px;
                color: #7dacfa;
                padding: 3px 4px;
                border: 1px solid #7dacfa;
                margin-left: 13px;
                position: relative;
                bottom: 6px;
            }
        }
    }
}
</style>

<style lang="scss">
.landing_tab .el-date-editor .el-range__close-icon {
  display: none;
}
.landing_tab em {
    color: #ef3819;
    text-decoration: underline #ef3819;
}
</style>
