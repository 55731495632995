<template>
    <div class="area_main">
        <div v-if="reportStatus == 2 || reportStatus == 3">
            <div class="area_select">
                <span>推广平台：</span>
                <span @click="changePlatform('')" class="platform" :class="platform == ''?'selected':''" style="border-top-left-radius: 5px; border-bottom-left-radius: 5px">全部</span>
                <span @click="changePlatform('baidu_pc')" class="platform" :class="platform == 'baidu_pc'?'selected':''" style="right: 1px">百度PC</span>
                <span @click="changePlatform('baidu_h5')" class="platform" :class="platform == 'baidu_h5'?'selected':''" style="right: 2px; border-top-right-radius: 5px; border-bottom-right-radius: 5px">百度移动</span>
<!--                <span @click="changePlatform('360_pc')" class="platform" :class="platform == '360_pc'?'selected':''" style="right: 3px; border-top-right-radius: 5px; border-bottom-right-radius: 5px">360PC</span>-->
<!--                <span @click="changePlatform('360_h5')" class="platform" :class="platform == '360_h5'?'selected':''" style="right: 4px; border-top-right-radius: 5px; border-bottom-right-radius: 5px">360移动</span>-->
            </div>
            <div class="info">
                <span>共在
                    <span class="red">{{tableData.dis_province}}</span>个省（直辖市）
                    <span class="red">{{tableData.dis_city}}</span>个地级市采集到广告
                </span>
                <span>更新时间：{{tableData.report_generate_time}}</span>
            </div>
            <el-table
                border
                v-if="reportStatus == 2"
                :data="tableData.rows"
                row-key="value"
                :row-style="function({row, rowIndex}) {
                  if(!row.children) {
                    return {'backgroundColor': '#f8fafc'}
                  }
                }"
                v-loading="loading"
                empty-text="暂无相关数据"
                :tree-props="{children: 'children', hasChildren: 'hasChildren'}"
                :header-cell-style="{background:'#f8fafc', color: '#666666'}">
                <el-table-column
                    width="80"
                    align="center"
                    type="index"
                    label="序号">
                    <template slot-scope="scope">
                        <span>{{scope.row.index}}</span>
                    </template>
                </el-table-column>
                <el-table-column
                    min-width="80"
                    prop="label"
                    align="center"
                    label="省（直辖市）">
                </el-table-column>
                <el-table-column
                    min-width="120"
                    align="center"
                    prop="total"
                    label="采集到广告的样本关键词数">
                </el-table-column>
                <el-table-column
                    min-width="350"
                    label="采集到广告的关键词样例">
                    <template slot-scope="scope">
                        <span v-for="(item, i) in scope.row.ad_words.slice(0, 3)" :key="i">
                            {{item + (i == 2?scope.row.ad_words.length > 3?'等':'':scope.row.ad_words.length < 3 && i == scope.row.ad_words.length - 1?'':'、')}}
                        </span>
                        <span @click="getAllWord(scope.row.value)" class="canClick" style="float: right" v-if="scope.row.ad_words && scope.row.ad_words.length > 3">查看更多</span>
                    </template>
                </el-table-column>
            </el-table>
        </div>
        <div class="no_data" v-if="allEmpty">
            <img class="empty_img" src="../../../assets/img/compare/empty.png" height="144" width="187"/>
            <span>暂无数据</span>
        </div>
        <template v-else>
            <div class="no_data" v-if="reportStatus == 0" v-loading="tabLoading">
                <img class="empty_img" src="../../../assets/img/compare/empty.png" height="144" width="187"/>
                <span>暂无数据。点击“分析推广地域”，创建查询任务</span>
                <span class="sub">注：每个账号每天可申请分析1次（可选任意一家公司）</span>
                <myButton
                    width="130"
                    height="33"
                    @click="createReport"
                    type="default">
                    <img slot="img" src="../../../assets/img/companyDetails/analyse_icon.png" alt="" width="14" height="14">
                    <span slot="text">分析推广地域</span>
                </myButton>
            </div>
            <div class="no_data" v-if="reportStatus == 1">
                <img class="empty_img" src="../../../assets/img/compare/searching.png" height="144" width="187"/>
                <span>数据生成中</span>
            </div>
            <div class="no_data" v-if="reportStatus == -1 || reportStatus == 3">
                <img class="empty_img" src="../../../assets/img/compare/empty.png" height="144" width="187"/>
                <span v-if="reportStatus == -1" style="color: #999; font-size: 12px; margin-bottom: 20px">更新时间：{{tableData.report_generate_time}}</span>
                <span>暂无数据</span>
            </div>
            <div class="list_bottom" v-if="reportStatus != 0 && reportStatus != 1" :style="{'margin-top': reportStatus == -1?'190px':''}">
                <span class="canClick"  @click="createReport">重新分析</span>
                <span class="sub">注：每个账号每天可申请分析1次（可选任意一家公司）</span>
            </div>
        </template>

        <dragDialog title="查看关键词" v-model="dialogVisible">
            <template slot="body">
                <el-table
                    border
                    v-loading="dialogLoading"
                    :data="dialogList">
                    <el-table-column
                        width="80"
                        align="center"
                        label="序号"
                        prop="index">
                    </el-table-column>
                    <el-table-column
                        show-overflow-tooltip
                        label="关键词"
                        prop="word">
                    </el-table-column>
                </el-table>
            </template>
            <template slot="footer">
                <myButton
                    width="80"
                    height="33"
                    @click="dialogVisible = false"
                    type="default">
                    <span slot="text">确定</span>
                </myButton>
            </template>
        </dragDialog>
    </div>
</template>

<script>
  import myButton from "../../../components/myButton/myButton";
  import * as api from "@/api/companyDetails"
  import dragDialog from "../../../components/dialogs/drag_dialog";

  export default {
    name: "areaTab",
    props: ['digest', 'allEmpty'],
    components: {
      myButton,
      dragDialog
    },
    data() {
      return {
        platform: '',
        tableData: {},
        loading: false,
        tabLoading: false,
        reportStatus: 0,
        dialogVisible: false,
        dialogList: [],
        dialogLoading: false
      }
    },
    methods: {
      changePlatform(index) {
        this.platform = index;
        this.init()
      },
      createReport() {
        if (this.$store.state.userInfo.vip_type == 10) {
          this.$emit('noVipCommit')
          return
        }
        let params = {
          digest: this.digest
        }
        let p = new Promise((resolve, reject) => {
          api.post_word_area_reports(params).then(res => {
            if(res.data.result_code == 0) {
              this.$message({
                type: 'success',
                message: '任务创建成功'
              })
              this.init()
              resolve(res.data.data);
            } else {
              this.$message({
                type: 'error',
                message: res.data.message
              })
            }
          })
        })
        return p;
      },
      getAllWord(code) {
        this.dialogVisible = true
        this.dialogLoading = true
        let params = {
          digest: this.digest,
          code,
          has_report: 1
        }
        let p = new Promise((resolve, reject) => {
          api.get_more_word_for_area(params).then(res => {
            if(res.data.result_code == 0) {
              this.dialogList = res.data.data
              resolve(res.data.data);
            } else {
              this.$message({
                type: 'error',
                message: res.data.message
              })
            }
            this.dialogLoading = false
          })
        })
        return p;
      },
      init() {
        let params = {
          digest: this.digest,
          platform: this.platform
        }
        this.tabLoading = true;
        this.loading = true;
        let p = new Promise((resolve, reject) => {
          api.get_word_area_reports_list(params).then(res => {
            if(res.data.result_code == 0) {
              this.tableData = res.data.data
              this.reportStatus = res.data.data.status
              this.tabLoading = false
              this.loading = false
              resolve(res.data.data);
            }
          })
        })
        return p;
      }
    }
  }
</script>

<style scoped lang="scss">
.area_main {
    .canClick {
        color: #1f81f8;
        cursor: pointer;
    }
    .red {
        color: #ef3819;
    }
    .area_select {
        width: calc(100% - 40px);
        padding: 28px 16px;
        font-size: 14px;
        color: #333;
        border: solid 1px #ebeff2;
        .platform {
            position: relative;
            padding: 8px 16px;
            border: solid 1px #ebeff2;
            cursor: pointer;
            transition: all 0.5s;
        }
        .selected {
            color: #1f81f8;
            border: solid 1px #1f81f8;
            z-index: 2;
        }
    }
    .info {
        display: flex;
        justify-content: space-between;
        font-size: 14px;
        color: #999999;
        margin-top: 24px;
        margin-bottom: 18px;
    }
    .no_data {
        display: flex;
        flex-direction: column;
        align-items: center;
        font-size: 16px;
        color: #333333;
        padding: 50px ;
        .sub {
            color: #999999;
            margin-top: 6px;
            margin-bottom: 26px;
        }
    }
    .list_bottom {
        margin-top: 15px;
        .canClick {
            margin-left: 15px;
            font-size: 14px;
        }
        .sub {
            margin-left: 15px;
            font-size: 12px;
            color: #999999;
        }
    }
}
</style>

<style lang="scss">
    .drag_dialog {
        .el-table th {
            background-color: #f8fafc;
        }
    }
    .area_main .el-table th.is-leaf {
        padding: 8px;
    }
</style>
