<template>
    <div class="company_details">
        <div class="company_info">
            <div class="left">
                <div class="list-left-txt" :style="{ background: company.logo_color }">
                    <font>{{ company.logo_img_str }}</font>
                </div>
                <el-button class="collect" v-if="!company.collect"  @click="addCollect">
                    <img src="@/assets/img/companyDetails/collect.png" alt="" width="14" height="14">
                    <div>收藏</div>
                </el-button>
                <el-button class="collect" v-else @click="cancelCollect">
                    <img src="@/assets/img/companyDetails/collected.png" alt="" width="14" height="14">
                    <div>已收藏</div>
                </el-button>
                <el-button class="update"  @click="update" :disabled="hadUpdate">
                    <img v-if="!hadUpdate" :style="'transform: rotate('+ angle +'deg)'" src="@/assets/img/companyDetails/refresh.png" alt="" width="14" height="14">
                    <img v-else style="transform: rotate(360deg);" src="@/assets/img/companyDetails/unrefresh.png" alt="" width="14" height="14">
                    <div>更新数据</div>
                </el-button>
            </div>
            <div class="right">
                <my-button
                    v-if="!company.monitor"
                    class="info_btn"
                    :type="buttonDisable?'secondary':'primary'"
                    width="100" height="35"
                    @click="handelMonitor(companyDigest,1)"
                    :disable="buttonDisable"
                    :style="buttonDisable?'background-color: #b5c2d2;':''">
                    <img slot="img" src="@/assets/img/companyDetails/supervise.png" alt="" width="14px" height="14px">
                    <span v-if="buttonDisable" slot="text" style="color: #ffffff">监测推广</span>
                    <span v-else slot="text">监测推广</span>
                </my-button>
                <div class="on_monitor" v-else @mouseover="hoverButton=true" @mouseleave="hoverButton=false">
                    <my-button class="info_btn" type="secondary" width="100" height="35" style="background-color: #b5c2d2;">
                        <img slot="img" src="@/assets/img/companyDetails/supervise.png" alt="" width="14px" height="14px">
                        <span slot="text" style="color: #ffffff">监测中</span>
                    </my-button>
                    <div class="dialog" v-if="hoverButton">
                        <div class="line" @click="$router.push('/box/dynamic')">查看监测动态</div>
                        <div class="line" @click="cancelMonitor(companyDigest, 1)">取消监测</div>
                    </div>
                </div>
                <myButton v-if="$store.state.userInfo.has_export_permission" type="default" width="100" height="33" @click="showTel" style="position: absolute;right: 25px;top: 72px;">
                    <img slot="img" src="@/assets/img/companyDetails/phone.png" alt="" width="14px" height="14px">
                    <span slot="text">联系电话</span>
                </myButton>
                <div class="title">
                    <span>{{company.company_name || '加载中.....'}}</span>
                    <span :class="company.company_status=='在业' || '续存'?'running':'close_down'">{{company.company_status}}</span>
                </div>
                <div class="information">
                    <span class="information_item">
                        <span>法定代表人：</span>
                        <span class="data">{{company.legal_person || '--'}}</span>
                    </span>
                    <span class="information_item">
                        <span>成立日期：</span>
                        <span class="data">{{company.establish_date || '--'}}</span>
                    </span>
                    <span class="information_item">
                        <span>注册资本：</span>
                        <span class="data">{{company.capital || '--'}}</span>
                    </span>
                </div>
                <div class="address text-ellipsis">
                    <span>企业地址：</span>
                    <span :title="company.company_address" class="data">{{company.company_address || '--'}}</span>
                </div>
                <div class="extend_box">
                    <div class="extend_item" style="cursor: pointer" @click="handleTab('landing')">
                        <span>推广落地页</span>
                        <span class="blue_data">{{parseInt(company.land_page_cnt) || '--'}}<span>个</span></span>
                    </div>
                    <div class="line"></div>
                    <div class="extend_item" style="cursor: pointer" @click="handleTab('word')">
                        <span>推广关键词
                            <el-tooltip popper-class="my_popper" placement="bottom" effect="light">
                                <div style="width: 200px" slot="content">AI竞投通过数据库中的8千多万竞价词，模拟人工搜索，若某公司的广告有展现，
                                    则将词收录到该公司。受投放时段、展现状态、关键词匹配模式等影响，采集的关键词有一定出入</div>
                              <img width="12px" height="12px" src="@/assets/img/search/icon_more.png">
                            </el-tooltip>
                       </span>
                        <span class="blue_data">{{company.nAdWordCnt || '--'}}<span>个</span></span>
                    </div>
                    <div class="line"></div>
                    <div class="extend_item" style="cursor: pointer" @click="handleTab('history')">
                        <span>推广记录</span>
                        <span class="blue_data">{{company.nAdCnt || '--'}}<span>条</span></span>
                    </div>
                    <div class="line"></div>
                    <div class="extend_item" style="cursor: pointer" @click="handleTab('area')">
                        <span>推广地域</span>
                        <span class="blue_data">{{company.dis_province || '-'}}<span>省</span></span>
                    </div>
                    <div class="line"></div>
                    <div class="extend_item" style="cursor: pointer" @click="handleTab('link')">
                        <span>推广域名</span>
                        <span class="blue_data">{{company.nAdLinkCnt || '--'}}<span>个</span></span>
                    </div>
                    <div class="line"></div>
                    <div class="extend_item" style="cursor: pointer" @click="handleTab('company')">
                        <span>推广竞品</span>
                        <span class="blue_data">{{company.nAdCompeteCnt || '--'}}<span>个</span></span>
                    </div>
                </div>
                <div class="platform_title">推广平台</div>
                <platformBox :list="company.nAdPlatform || []" @childClick="clickPlatform"></platformBox>
            </div>
        </div>
        <div class="list_info">
            <el-tabs v-model="activeName" @tab-click="" id="list_tab" :before-leave="tabChange">
                <el-tab-pane name="word" :disabled="disabledTap">
                    <template slot="label">
                        <span>推广关键词 </span>
                        <span class="tab_count">{{company.nAdWordCnt > 99999999? '99999999+':company.nAdWordCnt}}</span>
                    </template>
                    <div class="date_picker">
                        <span>最近发现时间：</span>
                        <span class="picker" :class="tabIndex == 0?'active':''" @click="changeTabIndex(0)">全部</span>
                        <span class="picker" :class="tabIndex == 1?'active':''" @click="changeTabIndex(1)">近1天</span>
                        <span class="picker" :class="tabIndex == 2?'active':''" @click="changeTabIndex(2)">近7天</span>
                        <span class="picker" :class="tabIndex == 3?'active':''" @click="changeTabIndex(3)">近30天</span>
                        <span :style="{'color': wordDatePicker.length == 0?'#333':'#1f81f8'}">自定义：
                            <el-date-picker
                                style="margin-left: 10px; width: 250px"
                                v-model="wordDatePicker"
                                @change="changeWordDate"
                                type="daterange"
                                value-format="yyyy-MM-dd"
                                range-separator="至"
                                start-placeholder="开始日期"
                                end-placeholder="结束日期">
                            </el-date-picker>
                        </span>
                    </div>
                    <div class="handle_box">
                        <span class="table_cnt">共找到<span class="red_text">{{tabIndex == 0?company.nAdWordCnt > 99999999? '99999999+':company.nAdWordCnt:wordConfig.real_cnt || '--'}}</span>个关键词</span>
                        <myButton type="default" width="100" height="40" @click="exportWordList">
                            <img slot="img" src="@/assets/img/companyDetails/icon_export.png" alt="" width="14px" height="14px">
                            <span slot="text">立即导出</span>
                        </myButton>
                    </div>
                    <el-table
                        class="word_table"
                        border
                        :data="wordList"
                        v-loading="loading5"
                        empty-text="暂无相关数据"
                        @sort-change="wordListSortChange"
                        :header-cell-style="{background:'#f8fafc', color: '#666666'}">
                        <el-table-column
                            min-width="100"
                            label="关键词">
                            <template slot-scope="scope">
                                <span class="three_line">{{scope.row.word}}</span>
                            </template>
                        </el-table-column>
                        <el-table-column
                            min-width="70"
                            align="center"
                            prop="idx_lable"
                            label="关键词特点">
                            <template slot-scope="scope">
                                <el-tooltip popper-class="my_popper"  effect="light" width="100" placement="right-start" content="竞争度低且搜索量较高的关键词" v-if="scope.row.idx_lable == '黑马'">
                                    <div class="hm">黑马</div>
                                </el-tooltip>
                                <el-tooltip popper-class="my_popper" effect="light" width="100" placement="right-start" content="搜索量高或点击量高的关键词" v-if="scope.row.idx_lable == '热搜'">
                                    <div class="rs">热搜</div>
                                </el-tooltip>
                                <div v-if="scope.row.idx_lable != '黑马' && scope.row.idx_lable != '热搜'">{{scope.row.idx_lable}}</div>
                            </template>
                        </el-table-column>
                        <el-table-column
                            min-width="60"
                            align="center"
                            label="竞价激烈程度">
                            <template slot-scope="scope">
                                <span>{{scope.row.idx_competition ||'-'}}</span>
                            </template>
                        </el-table-column>
                        <el-table-column
                            min-width="60"
                            align="center"
                            label="PC日搜索量">
                            <template slot-scope="scope">
                                <span>{{scope.row.idx_pc_pv < 5?'<5':scope.row.idx_pc_pv}}</span>
                            </template>
                        </el-table-column>
                        <el-table-column
                            min-width="60"
                            align="center"
                            label="移动日搜索量">
                            <template slot-scope="scope">
                                <span>{{scope.row.idx_mobile_pv < 5?'<5':scope.row.idx_mobile_pv}}</span>
                            </template>
                        </el-table-column>
                        <el-table-column
                            min-width="60"
                            align="center"
                            label="搜索指数">
                            <template slot-scope="scope">
                                <span>{{scope.row.idx_week_index == 0?'无':scope.row.idx_week_index}}</span>
                            </template>
                        </el-table-column>
                        <el-table-column
                            min-width="60"
                            align="center"
                            label="SEM参考价格">
                            <template slot-scope="scope">
                                <span>{{scope.row.idx_bid == 0?'价格统计中':scope.row.idx_bid}}</span>
                            </template>
                        </el-table-column>
                        <el-table-column
                            min-width="65"
                            prop="company_cnt"
                            sortable="custom"
                            align="center"
                            label="竞价公司">
                            <template slot-scope="scope">
                                <span class="to_ad" @click="$router.push({path: '/box/search/findAdvertising',query:{word: scope.row.word}})">
                                    {{scope.row.company_cnt == '0'?'数据统计中':scope.row.company_cnt}}
                                </span>
                            </template>
                        </el-table-column>
                        <el-table-column
                            min-width="80"
                            align="center"
                            sortable="custom"
                            label="本公司推广记录"
                            prop="record_cnt">
                            <template slot-scope="scope">
                                <div v-if="scope.row.record_cnt != '0'" style="color: #1f81f8">{{scope.row.record_cnt}}</div>
                                <div v-else style="font-size: 12px; color: #999;">数据更新中</div>
                                <my-button @click="handleRecord(scope)" style="border-radius: 2px;" width="43" height="23" type="default">
                                    <span slot="text">查看</span>
                                </my-button>
                            </template>
                        </el-table-column>
                        <el-table-column
                            min-width="100"
                            prop="ad_time_from"
                            label="最早发现时间">
                            <template slot-scope="scope">
                                <span>{{scope.row.ad_time_from || '暂无数据'}}</span>
                            </template>
                        </el-table-column>
                        <el-table-column
                            min-width="100"
                            sortable="custom"
                            prop="find_time"
                            label="最近发现时间">
                        </el-table-column>
                        <el-table-column
                            align="center"
                            min-width="60"
                            prop="promotion_days"
                            label="在线天数">
                            <template slot-scope="scope">
                                <span>{{scope.row.promotion_days == 0?'数据统计中':scope.row.promotion_days}}</span>
                            </template>
                        </el-table-column>
                        <el-table-column
                            align="center"
                            width="70"
                            label="操作">
                            <template slot-scope="scope">
                                <el-button type="text" v-if="!scope.row.monitor" @click="handelMonitor(scope.row.word,2, 'word')">监测</el-button>
                                <el-button type="text" disabled v-if="scope.row.monitor">监测中</el-button>
                                <el-button type="text" style="margin: 0" v-if="scope.row.monitor" @click="cancelMonitor(scope.row.word,2, 'word')">取消</el-button>
                            </template>
                        </el-table-column>
                    </el-table>
                    <page :config="wordConfig" @pageChange="wordPageChange" @limitChange="wordLimitChange"></page>
                </el-tab-pane>
                <el-tab-pane name="history" :disabled="disabledTap">
                    <template slot="label">
                        <span>推广记录 </span>
                        <span class="tab_count">{{company.nAdCnt > 99999999? '99999999+':company.nAdCnt}}</span>
                    </template>
                    <div class="history_search">
                        <div class="word">
                            <span>关键词：</span>
                            <el-input
                                type="text"
                                ref="input"
                                v-model="historyKeyWord"
                                style="width: 345px"
                                @keyup.enter.native="getHistoryData"
                                placeholder="搜索关键词">
                                <template #append style="width: 48px">
                                    <div class="box" @click="getHistoryData">
                                        <img src="@/assets/img/search/icon_search.png" alt="" width="18px" height="18px"/>
                                    </div>
                                </template>
                            </el-input>
                            <div class="check_box">
                                <span class="tip">
                                    <el-checkbox v-model="history_query_type" :true-label="1" :false-label="2" @change="getHistoryData">精准匹配关键词</el-checkbox>
                                    <img src="@/assets/img/search/icon_more.png" alt="" @mouseover="showMoreDialog = true" @mouseleave="showMoreDialog = false">
                                </span>
                                <div class="more_info" v-if="showMoreDialog">
                                    <div class="test_triangle_border">
                                        <div class="popup">
                                            <span><em></em></span>
                                            根据输入关键词精准匹配搜索结果
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="platform">
                            <span>推广平台：</span>
                            <platformSelector ref="platformSelector" @childChange="getPlatformSelection" :list="company.nAdPlatform || []"></platformSelector>
                        </div>
                    </div>
                    <div class="handle_box" style="margin-top: 20px">
                        <span class="table_cnt">共找到<span class="red_text">{{historyConfig.real_cnt > 99999999? '99999999+':historyConfig.real_cnt}}</span>条推广记录</span>
                        <myButton type="default" width="100" height="40" @click="exportList">
                            <img slot="img" src="@/assets/img/companyDetails/icon_export.png" alt="" width="14px" height="14px">
                            <span slot="text">立即导出</span>
                        </myButton>
                    </div>
                    <el-table
                        border
                        :data="historyList"
                        v-loading="loading3"
                        empty-text="暂无相关数据"
                        @sort-change="historyListSortChange"
                        :header-cell-style="{background:'#f8fafc', color: '#666666'}">
                        <el-table-column
                            min-width="120"
                            show-overflow-tooltip
                            label="关键词">
                            <template slot-scope="scope">
                                <span>{{scope.row.ad_word}}</span>
                            </template>
                        </el-table-column>
                        <el-table-column
                            min-width="80"
                            show-overflow-tooltip
                            label="推广平台">
                            <template slot-scope="scope">
                                <span>{{$C.platformTurn(scope.row.platform)}}</span>
                            </template>
                        </el-table-column>
                        <el-table-column
                            show-overflow-tooltip
                            min-width="100"
                            label="推广域名">
                            <template slot-scope="scope">
                                <a :href="'http://'+scope.row.domain" target="_blank" style="color: #333" @click="handleFire(3191)">{{scope.row.domain}}</a>
                            </template>
                        </el-table-column>
                        <el-table-column
                            show-overflow-tooltip
                            min-width="150"
                            label="创意标题">
                            <template slot-scope="scope">
                                <span style="color: #1f81f8; cursor: pointer" @click="$C.jump(scope.row.land_url, 3192)">{{scope.row.title}}</span>
                            </template>
                        </el-table-column>
                        <el-table-column
                            min-width="150"
                            prop="content"
                            label="创意描述">
                            <template slot-scope="scope">
                                <div :title="scope.row.content" class="two_line">{{scope.row.content || '暂无数据'}}</div>
                            </template>
                        </el-table-column>
                        <el-table-column
                            align="center"
                            min-width="100"
                            label="平均竞价排名">
                            <template slot-scope="scope">
                                <div v-if="scope.row.rank_avg" style="color: #1f81f8">{{scope.row.rank_avg}}</div>
                                <div v-else>暂无数据</div>
                                <my-button v-if="scope.row.rank_avg" @click="showHistoryDialog(scope)" style="border-radius: 2px;" width="43" height="23" type="default">
                                    <span slot="text">查看</span>
                                </my-button>
                            </template>
                        </el-table-column>
                        <el-table-column
                            sortable="custom"
                            min-width="120"
                            prop="ad_time_to"
                            label="最后推广时间">
                        </el-table-column>
                        <el-table-column
                            label="操作">
                            <template slot-scope="scope">
                                <el-button type="text" v-if="!scope.row.monitor" @click="handelMonitor(scope.row.ad_word,2, 'history')">监测</el-button>
                                <el-button type="text" disabled v-if="scope.row.monitor">监测中</el-button>
                                <el-button type="text" v-if="scope.row.monitor" @click="cancelMonitor(scope.row.ad_word,2, 'history')">取消</el-button>
                            </template>
                        </el-table-column>
                        <template #empty>
                            <no-data>
                                <img slot="img" src="../../../assets/img/compare/empty.png" style="width: 187px; height: 144px"/>
                                <span slot="text" style="line-height: 14px">暂无推广记录</span>
                            </no-data>
                        </template>
                    </el-table>
                    <page :config="historyConfig" @pageChange="historyPageChange" @limitChange="historyLimitChange"></page>
                </el-tab-pane>
                <el-tab-pane name="time" :disabled="disabledTap">
                    <template slot="label">
                        <span>推广时段 </span>
                        <img class="new" src="../../../assets/img/companyDetails/new_icon.png" alt="" width="28" height="12">
                    </template>
                    <adTime ref="time"></adTime>
                </el-tab-pane>
                <el-tab-pane name="area" :disabled="disabledTap">
                    <template slot="label">
                        <span>推广地域 </span>
                        <span class="tab_count">{{company.dis_province >0?company.dis_province:''}}</span>
                        <img class="new" src="../../../assets/img/companyDetails/new_icon.png" alt="" width="28" height="12">
                    </template>
                    <areaTab ref="areaTab" v-if="$store.state.userInfo.vip_type != 5" :digest="companyDigest" :all-empty="company.nAdCnt == 0" @noVipCommit="handelCommit"></areaTab>
                    <div v-else style="width: 100%; height: 100%; background-color: #fff; overflow: hidden">
                        <NoData>
                            <img slot="img" style="width: 187px; margin-bottom: 13px; margin-top: 176px" src="../../../assets/img/icon/no_jurisdiction.png"/>
                            <div slot="text" style="text-align: center">
                                <div style="font-size: 16px; color: #333333; margin-bottom: 16px">暂无权限</div>
                                <div style="color: #999999; font-size: 14px;">标准版会员可查看更多价值数据，请联系在线客服开通</div>
                            </div>
                        </NoData>
                    </div>
                </el-tab-pane>
                <el-tab-pane name="landing" :disabled="disabledTap">
                    <template slot="label">
                        <span>推广落地页 </span>
                        <span class="tab_count">{{company.land_page_cnt >=0?company.land_page_cnt:''}}</span>
                        <img class="new" src="../../../assets/img/companyDetails/new_icon.png" alt="" width="28" height="12">
                    </template>
                    <landingTab ref="landing"></landingTab>
                </el-tab-pane>
                <el-tab-pane label="推广域名" name="link" :disabled="disabledTap">
                    <template slot="label">
                        <span>推广域名 </span>
                        <span class="tab_count">{{company.nAdLinkCnt > 99999999? '99999999+':company.nAdLinkCnt}}</span>
                    </template>
                    <el-table
                        border
                        :data="linkList"
                        v-loading="loading2"
                        empty-text="暂无相关数据"
                        @sort-change="linkListSortChange"
                        :header-cell-style="{background:'#f8fafc', color: '#666666'}">
                        <el-table-column
                            width="80"
                            label="序号">
                            <template slot-scope="scope">
                                <div style="text-align: center">{{scope.row.index}}</div>
                            </template>
                        </el-table-column>
                        <el-table-column
                            min-width="100"
                            label="推广域名">
                            <template slot-scope="scope">
                                <a target="_blank" :href="'http://' + scope.row.link" @click="handleFire(3194)">{{scope.row.link}}</a>
                            </template>
                        </el-table-column>
                        <el-table-column
                            width="250"
                            sortable="custom"
                            prop="last_ad_time"
                            label="最后推广时间">
                        </el-table-column>
                        <el-table-column
                            width="250"
                            sortable="custom"
                            label="推广记录数">
                            <template slot-scope="scope">
                                <span>{{scope.row.word_cnt || '-'}}</span>
                            </template>
                        </el-table-column>
                        <el-table-column
                            min-width="200"
                            label="推广关键词">
                            <template slot-scope="scope">
                                <span style="color: #333">{{scope.row.words.length > 0?scope.row.words.slice(0,3).join('、'):'-'}}</span>
                            </template>
                        </el-table-column>
                    </el-table>
                    <div style="position: relative">
                        <span style="color: #999; position: absolute; top: 25px;">注：推广域名下的推广记录数计算有一定的延迟，敬请谅解。</span>
                        <page :config="linkConfig" @pageChange="linkPageChange" @limitChange="linkLimitChange"></page>
                    </div>

                </el-tab-pane>
                <el-tab-pane name="company" :disabled="disabledTap">
                    <template slot="label">
                        <span>推广竞品 </span>
                        <span class="tab_count">{{company.nAdCompeteCnt > 99999999? '99999999+':company.nAdCompeteCnt}}</span>
                    </template>
                    <template v-if="$store.state.userInfo.vip_type != 5">
                        <div class="select_box">
                            <div class="area">
                                <span class="s-l-label">所在地区：</span>
                                <div class="s-l-box">
                                    <div class="cascader">
                                        <el-popover
                                            trigger="hover"
                                            popper-class="cascader-popper"
                                            placement="bottom-start"
                                            v-model="areaVisible">
                                            <el-cascader-panel
                                                :options="areaOptions"
                                                :props="props"
                                                v-model="search.s_area"
                                                @change="areaSelect">
                                            </el-cascader-panel>
                                            <span
                                                slot="reference"
                                                :class="{ 'cascader-span': area_name != '全部地区' }">
                            {{ area_name }}
                             <i class="el-icon-arrow-down el-icon--right"></i>
                        </span>
                                        </el-popover>
                                    </div>
                                </div>
                            </div>
                            <transition name="el-fade-in-linear">
                                <div class="select-list" v-if="area_name_str">
                                    <span class="select-list-label">已选条件：</span>
                                    <div class="select-list-box">
                        <span v-if="area_name_str">所在地区：
                            {{
                                area_name_str.length > 20
                                ? area_name_str.substring(0, 20) + "..."
                                : area_name_str
                            }}
                            <font @click="clearArea"></font>
                        </span>
                                    </div>
                                    <div class="select-list-clear" @click="clearArea">清除条件</div>
                                </div>
                            </transition>
                        </div>
                        <div class="handle_box" style="align-items: center">
                            <span class="table_cnt">共找到<span class="red_text">{{search.s_area.length == 0?company.nAdCompeteCnt > 99999999? '99999999+':company.nAdCompeteCnt:companyConfig.real_cnt || '--'}}</span>个推广竞品</span>
                            <myButton v-if="$store.state.userInfo.has_export_permission" type="default" width="158" height="33" @click="exportTel" class="button"  style="right: 130px;">
                                <img slot="img" src="@/assets/img/companyDetails/icon_export.png" alt="" width="14px" height="14px">
                                <span slot="text">导出企业联系电话</span>
                            </myButton>
                        </div>
                        <el-table
                            border
                            :data="companyList"
                            v-loading="loading4"
                            empty-text="暂无相关数据"
                            @sort-change="companyListSortChange"
                            :header-cell-style="{background:'#f8fafc', color: '#666666'}">
                            <el-table-column
                                width="80"
                                label="序号">
                                <template slot-scope="scope">
                                    <div style="text-align: center">{{scope.row.index}}</div>
                                </template>
                            </el-table-column>
                            <el-table-column
                                min-width="100"
                                show-overflow-tooltip
                                label="竞品公司">
                                <template slot-scope="scope">
                                    <span style="color: #1f81f8; cursor: pointer" @click="toCompany(scope.row.company_name_digest)">{{scope.row.compete_company_name}}</span>
                                </template>
                            </el-table-column>
                            <el-table-column
                                min-width="50"
                                label="所在地区"
                                prop="city">
                            </el-table-column>
                            <el-table-column
                                width="150"
                                sortable="custom"
                                prop="compete_word_cnt"
                                label="相同关键词数">
                                <template slot-scope="scope">
                                    <span>{{scope.row.compete_word_cnt}}</span>
                                </template>
                            </el-table-column>
                            <el-table-column
                                min-width="200"
                                label="相同关键词">
                                <template slot-scope="scope">
                                 <span v-for="(item, i) in scope.row.compete_word.split(' ')" :key="i">
                                    <span
                                        @click="compareWorld(scope, item)"
                                        class="new_link">{{item}}</span
                                    ><span v-if="i < scope.row.compete_word.split(' ').length-1">、</span>
                                </span>等
                                </template>
                            </el-table-column>
                            <el-table-column
                                min-width="70"
                                prop="lasttime"
                                label="最后推广时间">
                            </el-table-column>
                        </el-table>
                        <page :config="companyConfig" @pageChange="companyPageChange" @limitChange="companyLimitChange"></page>
                    </template>
                    <div v-else style="width: 100%; height: 100%; background-color: #fff; overflow: hidden">
                        <NoData>
                            <img slot="img" style="width: 187px; margin-bottom: 13px; margin-top: 176px" src="../../../assets/img/icon/no_jurisdiction.png"/>
                            <div slot="text" style="text-align: center">
                                <div style="font-size: 16px; color: #333333; margin-bottom: 16px">暂无权限</div>
                                <div style="color: #999999; font-size: 14px;">标准版会员可查看竞品公司，请联系在线客服开通</div>
                            </div>
                        </NoData>
                    </div>
                </el-tab-pane>
                <el-tab-pane name="info" :disabled="disabledTap">
                    <template slot="label">
                        <span>备案网站 </span>
                        <span class="tab_count">{{company.icp_cnt > 99999999? '99999999+':company.icp_cnt}}</span>
                    </template>
                    <el-table
                        border
                        :data="infoList"
                        v-loading="loading"
                        empty-text="暂无相关数据"
                        @sort-change="infoListSortChange"
                        :header-cell-style="{background:'#f8fafc', color: '#666666'}">
                        <el-table-column
                            width="80"
                            label="序号">
                            <template slot-scope="scope">
                                <div style="text-align: center">{{scope.row.index}}</div>
                            </template>
                        </el-table-column>
                        <el-table-column
                            show-overflow-tooltip
                            prop="website_name"
                            label="网站名称">
                        </el-table-column>
                        <el-table-column
                            show-overflow-tooltip
                            label="网址">
                            <template slot-scope="scope">
                                <a target="_blank" :href="scope.row.domain_url" @click="handleFire(3197)">{{scope.row.domain}}</a>
                            </template>
                        </el-table-column>
                        <el-table-column
                            show-overflow-tooltip
                            prop="master_number"
                            label="网站备案/许可证号">
                        </el-table-column>
                        <el-table-column
                            sortable="custom"
                            width="125"
                            prop="review_date"
                            label="审核日期">
                        </el-table-column>
                    </el-table>
                    <page :config="infoConfig" @pageChange="infoPageChange" @limitChange="infoLimitChange"></page>
                </el-tab-pane>
            </el-tabs>
        </div>

        <export_dialog
            @beforeClose="dialogVisible = false"
            @handleExport="distinguishExport"
            :visible.sync="dialogVisible"
            :all-cnt="parseInt(exportCnt)"
            :surplus="surplus"
            :fast-limit="10000"
            :total-limit="200000"
            width="60%">
        </export_dialog>

        <common_dialog
            @beforeClose="notVipShow = false"
            :visible.sync="notVipShow"
            :text="commonDialogText"
            width="40%">
        </common_dialog>

        <dragDialog title="竞价排名" v-model="historyDialog">
            <template slot="body">
                <table class="history_drag_dialog">
                    <tr>
                        <th style="line-height: 24px;">平台 <br> 排名</th>
                        <th><span style="position: relative; top: 13px">{{selectHistoryItem.rank_avg}}</span></th>
                    </tr>
                    <tr>
                        <td style="line-height: 24px;"><span style="position: relative; bottom: 80px">排名趋势</span></td>
                        <td style="padding: unset">
                            <div id="dialogChart"></div>
                        </td>
                    </tr>
                </table>
            </template>
            <template slot="footer">
                <myButton
                    width="80"
                    height="33"
                    @click="historyDialog = false"
                    type="default">
                    <span slot="text">确定</span>
                </myButton>
            </template>
        </dragDialog>

        <DialogBox
            title="企业联系电话"
            width="435"
            contentWidth="100%"
            contentMargin="0"
            v-show="telDialogList"
            @closeFn="closeFn">
            <div slot="content" class="dialog-content">
                <div class="selections">
                    <div class="selection_item" :class="telType == 0?'active':''" @click="telType = 0">全部 <span>{{telData.total_rows && telData.total_rows.length}}</span></div>
                    <div class="selection_item" :class="telType == 1?'active':''" @click="telType = 1">高质量电话 <span>{{telData.kp_rows && telData.kp_rows.length}}</span></div>
                    <div class="selection_item" :class="telType == 2?'active':''" @click="telType = 2">固话 <span>{{telData.tel_rows && telData.tel_rows.length}}</span></div>
                </div>
                <div class="tel_list scrollbar-class">
                    <div class="list-item" v-for="(item, i) in telType == 0?telData.total_rows:telType == 1?telData.kp_rows:telData.tel_rows" :key="i">
                        <div style="display: inline-flex; align-items: center">
                            <img v-if="item.type == 1" src="@/assets/img/companyDetails/phone_icon.png" width="20" height="20" alt="">
                            <img v-if="item.type == 2" src="@/assets/img/companyDetails/tel_icon.png" width="20" height="20" alt="">
                            <img class="height" v-if="item.type == 1 && item.kp" src="@/assets/img/companyDetails/h_phone_icon.png" width="14" height="14"  alt="">
                            <span class="number">{{item.show_str}}</span>
                        </div>
                        <div>
                            <span class="text">准确性</span>
                            <span class="percent">{{item.proportion}}%</span>
                        </div>
                    </div>
                </div>
                <div class="footer_text">
                    源自于互联网公开数据，准确性基于大数据分析和用户反馈信息
                </div>
            </div>
        </DialogBox>

        <el-dialog
            custom-class="telephone_dialog"
            title="导出数据"
            :visible.sync="telDialog">
            <div class="line1">
                <span>数据量：</span>
                <span style="color: #1f81f8">{{exportCntTel}}</span>条
            </div>
            <div class="time">
                <span>今日还可以免费导出<span style="color: #ff8840;">{{surplusTel}}</span>次</span>
            </div>
            <span slot="footer" class="dialog-footer">
                <my-button @click="telDialog = false" type="secondary" width="80" height="33" style="margin-right: 11px">
                    <span slot="text">取消</span>
                </my-button>
                <my-button @click="handelExportTel" type="primary" width="80" height="33">
                    <span slot="text">确定</span>
                </my-button>
            </span>
        </el-dialog>
    </div>
</template>

<script>
  import platformBox from "../../../components/platformBox/platformBox";
  import page from "../../../components/page";
  import platformSelector from "../../../components/platformSelector/platformSelector";
  import myButton from "../../../components/myButton/myButton";
  import * as api from "@/api/companyDetails"
  import common_dialog from "../../../components/dialogs/common_dialog";
  import export_dialog from "../../../components/dialogs/export_dialog";
  import dragDialog from "../../../components/dialogs/drag_dialog";
  import searchOption from "../search/searchOption"
  import PinganAna from "pingansec-vue-ana";
  import areaTab from "./areaTab";
  import landingTab from "./landingTab";
  import adTime from "./adTime";
  import * as echarts from 'echarts';

  export default {
    name: "index",
    mixins: [searchOption],
    components: {
      platformBox,
      page,
      platformSelector,
      myButton,
      common_dialog,
      export_dialog,
      dragDialog,
      areaTab,
      landingTab,
      adTime
    },
    data() {
      return {
        activeName: 'word',
        company: {
          nAdPlatform: [],
        },
        tabIndex: 0,
        hadUpdate: false, //刷新按钮
        angle: 0, //旋转角度
        telDialog: false,
        telDialogList: false,
        telData: {},
        telType: 0,
        wordList: [],
        wordListSort_key: '',
        wordListSort_type: '',
        wordDatePicker: [], //推广关键词时间选择器选择时间区间
        infoList: [],
        infoListSort: 1,
        linkList: [],
        linkListSort_key: '',
        linkListSort_type: '',
        historyList: [],
        historyKeyWord: '',
        history_query_type: 2,
        showMoreDialog: false,
        historyDialog: false,
        selectHistoryItem: {},
        historySort: 1,
        companyList: [],
        companyListSort_key: '',
        companyListSort_type: '',
        areaVisible: false,
        search: {
          s_area: []
        },
        props: { multiple: true,  expandTrigger: 'hover'},
        area_name_str: "",
        area_name: "全部地区",
        wordConfig: {
          page: 1,
          limit: 10,
          total: null,
          sizes: [10, 20, 30, 40, 50],
          count: 5,
          real_cnt: null
        },
        infoConfig: {
          page: 1,
          limit: 10,
          total: null,
          sizes: [10, 20, 30, 40, 50],
          count: 5
        },
        linkConfig: {
          page: 1,
          limit: 10,
          total: null,
          sizes: [10, 20, 30, 40, 50],
          count: 5
        },
        historyConfig: {
          page: 1,
          limit: 10,
          total: null,
          sizes: [10, 20, 30, 40, 50],
          count: 5,
          real_cnt: null
        },
        companyConfig: {
          page: 1,
          limit: 10,
          total: null,
          sizes: [10, 20, 30, 40, 50],
          count: 5,
          real_cnt: null
        },
        loading: false,
        loading2: false,
        loading3: false,
        loading4: false,
        loading5: false,
        platformSelection: [], //筛选平台列表
        hoverButton: false,
        notVipShow: false, //非vip导出提示弹窗
        commonDialogText: '', //弹窗内容
        dialogVisible: false, //导出列表确认弹窗
        surplus: null, //剩余导出次数
        surplusTel: null, //剩余导出次数
        exportCnt: null, //导出数量
        exportCntTel: null, //导出数量
        buttonDisable: false, //加载时按钮不可用
        is_first: true,
        disabledTap: false,
      }
    },
    computed: {
      companyDigest() {
        return this.$route.query.digest;
      },
      datePicker() {
        let date = {
          b_time: '',
          e_time: ''
        };
        switch (this.tabIndex) {
          case 1:
            date.b_time = this.$C.getBeforeDay(0);
            date.e_time = this.$C.getBeforeDay(0);
            break;
          case 2:
            date.b_time = this.$C.getBeforeDay(7);
            date.e_time = this.$C.getBeforeDay(0);
            break;
          case 3:
            date.b_time = this.$C.getBeforeDay(30);
            date.e_time = this.$C.getBeforeDay(0);
            break;
          case 4:
            date.b_time = this.wordDatePicker[0];
            date.e_time = this.wordDatePicker[1];
            break;
          default:
            date.b_time = ''
            date.e_time = ''
        };
        return date;
      }
    },
    created() {
      PinganAna.fire(3100)
      //企业详情页-推广关键词tab页展示
      PinganAna.fire(3180);
      if(this.$route.query.record_word) {
        this.activeName = 'history';
        this.history_query_type = 1;
        this.historyKeyWord = this.$route.query.record_word
        this.getHistoryData()
      }
      this.init();
    },
    updated() {
      if(this.activeName == 'word') {
        var dom = document.getElementsByClassName('cell_link')
        if(dom.length) {
          setTimeout(_=>{
            var width = (dom[0].clientWidth - 65) + 'px'
            for (var i = 0; i < dom.length; i++) {
              dom[i].children[0].style.setProperty('--max-width',width)
            }
          },100)
        }
      }
    },
    methods: {
      showTel() {
        this.telDialogList = true;
        this.getCompanyTel();
      },
      getCompanyTel() {
        let params = {
          digest: this.companyDigest,
          page: 1,
          limit: 10,
          link_type: 9
        }
        let p = new Promise((resolve, reject) => {
          api.get_link_list_by_digest(params).then(res => {
            if(res.data.result_code == 0) {
              this.telData = res.data.data.rows_plus
              resolve(res.data.data);
            } else {
              this.$message({
                type: 'error',
                message: res.data.message
              })
            }
          })
        })
        return p;
      },
      closeFn() {
        this.telDialogList = false;
      },
      exportTel() {
        api.get_last_export_cnt().then(res => {
          if(res.data.result_code==0) {
            this.surplusTel = res.data.data.total-res.data.data.used;
            this.exportCntTel = this.companyConfig.real_cnt > 1000?1000:this.companyConfig.real_cnt
            this.telDialog = true
          } else {
            this.$message({
              type: 'error',
              message: res.data.message
            })
          }
        })
      },
      handelExportTel() {
        let params = {
          export_type: '11',
          export_limit: this.exportCntTel,
          export_condition: JSON.stringify({
            digest: this.companyDigest,
            s_area: this.search.s_area,
            sort_key: this.companyListSort_key,
            sort_type: this.companyListSort_type,
            page: this.companyConfig.page,
            limit: this.companyConfig.limit,
          })
        }
        api.ge_export_task(params).then(res => {
          if(res.data.result_code==0) {
            this.$message({
              type: 'success',
              message: '导出任务创建成功！'
            })
            this.telDialog = false
            const { href } = this.$router.resolve({
              name: "export",
            });
            setTimeout(() => {
              window.open(href, '_blank');
            },500)
          } else {
            this.$message({
              type: 'error',
              message: res.data.message
            })
          }
        })
      },
      handleRecord(scope) {
        let {href} = this.$router.resolve({
          path: '/box/companyDetails',
          query: {
            digest: this.companyDigest,
            record_word: scope.row.word
          }
        })
        window.open(href, '_blank')
      },
      showHistoryDialog(scope) {
        this.historyDialog = true;
        this.selectHistoryItem = scope.row;
        this.$nextTick(() => {
          this.drawDialogLine()
        })
      },
      drawDialogLine() {
        let yData = []
        if(this.selectHistoryItem.rank.length == 1) {
          yData = [...this.selectHistoryItem.rank, ...this.selectHistoryItem.rank]
        } else {
          yData = [...this.selectHistoryItem.rank]
        }
        let xData = [];
        for(var i = 0; i < yData.length; i++) {
          xData.push(i)
        }
        let myChart = echarts.init(document.getElementById('dialogChart'))
        myChart.setOption({
          grid: {
            top: 50,
            bottom: 10,
          },
          xAxis: {
            type: 'category',
            data: xData,
            show: false,
          },
          yAxis: {
            type: 'value',
            inverse: true,
            minInterval: 1,
            min: 1,
            max: Math.max(...yData) > 7?Math.max(...yData):7,
            nameTextStyle: {
              color: '#999',
            }
          },
          series: [
            {
              data: yData,
              color:'#1f81f8',
              type: 'line',
              smooth: true,
              symbolSize: 0,
            }
          ]
        });
        window.addEventListener("resize", () => { myChart.resize(); });
      },
      handelCommit() {
        this.commonDialogText = '您当前为试用版会员，标准版会员可提交分析任务，请联系您的客户经理开通'
        this.notVipShow = true;
      },
      tabChange(activeName, oldActiveName) {
        if(this.$refs['areaTab'] && this.$refs['areaTab'].dialogVisible) {
          this.$refs['areaTab'].dialogVisible = false
        }
        switch (activeName) {
          case 'time':
            this.$refs['time'].init()
            break;
          case 'landing':
            this.$refs['landing'].init()
            break;
          case 'word':
            PinganAna.fire(3180);
            this.getWordData()
            break;
          case 'history':
            PinganAna.fire(3181);
            this.getHistoryData()
            break;
          case 'link':
            PinganAna.fire(3182);
            this.getLinkData()
            break;
          case 'company':
            PinganAna.fire(3183);
            if(this.$store.state.userInfo.vip_type != 5) {
              this.getCompanyData();
            }
            break;
          case 'info':
            PinganAna.fire(3184);
            this.getInfoData()
            break;
          case 'area':
            if(this.$store.state.userInfo.vip_type != 5) {
              this.$refs['areaTab'].init()
            }
            break;
        }
      },
      handleFire(point) {
        PinganAna.fire(point)
      },
      areaSelect(v) {
        let getAreaObj = {}
        v.map(el => {
          if (getAreaObj[el[0]]) {
            getAreaObj[el[0]].push(el[1])
          } else {
            getAreaObj[el[0]] = [el[1]]
          }
        })
        let num = 0
        this.area_name_str = []
        for (const key in getAreaObj) {
          if (Object.hasOwnProperty.call(getAreaObj, key)) {
            const el = getAreaObj[key];
            if (this.areaObj[key] == el.length) {
              num++
              this.area_name_str.push(key)
            } else {
              num += el.length
              this.area_name_str = this.area_name_str.concat(el)
            }
          }
        }
        this.area_name_str = this.area_name_str.join('/')
        this.getCompanyData()
        if(num==0) {
          this.area_name = '全部地区'
        } else {
          this.area_name = '已选' + num + '项'
        }
        this.search.s_area = v;
      },
      clearArea() {
        this.search.s_area = []
        this.area_name_str = ''
        this.area_name = '全部地区'
        this.getCompanyData()
      },
      update() {
        PinganAna.fire(3179)
        PinganAna.ready(() =>{
          PinganAna.userClickLog(
            '更新数据',
            '{"site":"企业详情页","当前内容":"'+this.company.company_name+'"}',
            '【AI竞投】系统',
            this.$store.state.userInfo.vip_type == 5?3:this.$store.state.userInfo.vip_type == 10?1:2,
            '--',
            this.$store.state.userInfo.account
          )
        })
        this.angle += 360;
        //给动画展示时间
        setTimeout(() => {
          this.hadUpdate = true;
          this.$message({
            type: 'success',
            message: '已收到更新请求，AI竞投将尽快为您更新数据!'
          })
          let params = {
            type: 'digest',
            str: this.companyDigest
          }
          api.ge_refresh_task(params).then(res => {
            if(res.data.result_code == 0) {
            } else {
              this.$message({
                type: 'error',
                message: res.data.message
              })
            }
          })
        },600)

      },
      changeWordDate() {
        this.tabIndex = 4;
        this.getWordData()
      },
      compareWorld(scope, item) {
        PinganAna.fire(3196)
        let {href} = this.$router.resolve({
          path: '/box/compare',
          query: {
            digest1: this.companyDigest,
            digest2: scope.row.company_name_digest,
            word: item
          }
        })
        window.open(href, '_blank')
      },
      changeTabIndex(index) {
        if(this.tabIndex != index) {
          this.tabIndex = index;
          this.wordDatePicker = [];
          this.getWordData()
        }
      },
      handleTab(name) {
        this.activeName = name
        var element = document.getElementById('list_tab');
        setTimeout(() =>{
          element.scrollIntoView({behavior: "smooth", alignToTop: true});
        },100)
      },
      clickPlatform(item) {
        this.$refs['platformSelector'].checkList = [item];
        this.platformSelection = [item]
        this.activeName = 'history'
        var element = document.getElementById('list_tab');
        setTimeout(() =>{
          element.scrollIntoView({behavior: "smooth", alignToTop: true});
        },100)
        this.getHistoryData();
      },
      wordListSortChange(column) {
        if(column.prop=='company_cnt') {
          this.wordListSort_key = 1
        } else if (column.prop=='record_cnt') {
          this.wordListSort_key = 2
        } else if(column.prop=='find_time'){
          this.wordListSort_key = 3
        } else {
          this.wordListSort_key = ''
        }
        if(column.order=='ascending') {
          this.wordListSort_type =1
        } else if (column.order=='descending') {
          this.wordListSort_type = 2
        } else {
          this.wordListSort_type = ''
        }
        this.getWordData();
      },
      infoListSortChange(column) {
        this.infoListSort = column.order == 'ascending'?'2':'1'
        this.getInfoData();
      },
      linkListSortChange(column) {
        if(column.prop=='word_cnt') {
          this.linkListSort_key = 2
        } else if (column.prop=='last_ad_time') {
          this.linkListSort_key = 1
        } else {
          this.linkListSort_key = ''
        }
        if(column.order=='ascending') {
          this.linkListSort_type =1
        } else if (column.order=='descending') {
          this.linkListSort_type = 2
        } else {
          this.linkListSort_type = ''
        }
        this.getLinkData();
      },
      companyListSortChange(column) {
        if(column.prop=='compete_word_cnt') {
          this.companyListSort_key = 2
        } else if (column.prop=='lasttime') {
          this.companyListSort_key = 1
        } else {
          this.companyListSort_key = ''
        }
        if(column.order=='ascending') {
          this.companyListSort_type =1
        } else if (column.order=='descending') {
          this.companyListSort_type = 2
        } else {
          this.companyListSort_type = ''
        }
        this.getCompanyData();
      },
      historyListSortChange(column) {
        this.historySort = column.order == 'ascending'?'2':'1'
        this.getHistoryData();
      },
      getPlatformSelection(list) {
        this.platformSelection = list
        this.getHistoryData();
      },
      wordPageChange(val) {
        this.wordConfig.page = val
        this.getWordData()
      },
      wordLimitChange(val) {
        this.wordConfig.page = 1;
        this.wordConfig.limit = val;
        this.getWordData()
      },
      infoPageChange(val) {
        this.infoConfig.page = val
        this.getInfoData()
      },
      infoLimitChange(val) {
        this.infoConfig.page = 1;
        this.infoConfig.limit = val;
        this.getInfoData()
      },
      linkPageChange(val) {
        this.linkConfig.page = val
        this.getLinkData();
      },
      linkLimitChange(val) {
        this.linkConfig.page = 1;
        this.linkConfig.limit = val;
        this.getLinkData();
      },
      historyPageChange(val) {
        this.historyConfig.page = val
        this.getHistoryData();
      },
      historyLimitChange(val) {
        this.historyConfig.page = 1;
        this.historyConfig.limit = val;
        this.getHistoryData();
      },
      companyPageChange(val) {
        this.companyConfig.page = val
        this.getCompanyData();
      },
      companyLimitChange(val) {
        this.companyConfig.page = 1;
        this.companyConfig.limit = val;
        this.getCompanyData();
      },
      addIndex(list,config) {
        for(var i = 0;i < list.length;i++) {
          list[i].index = (config.page-1)*config.limit+i+1
        }
        return list
      },
      handelMonitor(keyword,type, tab) {
        let params = {
          keyword,
          type
        }
        this.buttonDisable = true;
        api.add_monitor(params).then(res => {
          if(res.data.result_code==0) {
            this.$message({
              type: 'success',
              message: '监测成功！还可监测' +res.data.data.monitor_company_last +
                '家竞品公司，'+res.data.data.monitor_word_last+'个关键词'
            })
            type ==1?this.init():tab=='history'?this.getHistoryData():this.getWordData()
            type ==1?PinganAna.fire(3177):tab=='history'?PinganAna.fire(3193):PinganAna.fire(3188)
          } else {
            if(res.data.result_code == 120011 && (this.$store.state.userInfo.vip_type == 20 || this.$store.state.userInfo.vip_type == 10)) {
              this.commonDialogText = '您的监测数量已达上限，至尊版可监测50家竞品/100个关键词，如需监测更多，请联系您的客户经理开通'
              this.notVipShow = true;
            } else if(res.data.result_code == 120011 && this.$store.state.userInfo.vip_type == 5) {
              this.commonDialogText = '监测已达上限。标准版可监测竞品公司10家/关键词20个，请联系在线客服开通'
              this.notVipShow = true;
            } else {
              this.$message({
                type: 'error',
                message: res.data.message
              })
            }
          }
        })
      },
      cancelMonitor(keyword,type,tab) {
        let params = {
          keyword,
          type
        }
        api.cancel_monitor(params).then(res => {
          if(res.data.result_code==0) {
            this.$message({
              type: 'success',
              message: '已取消监测！'
            })
            type ==1?this.init():tab=='history'?this.getHistoryData():this.getWordData()
          } else {
            this.$message({
              type: 'error',
              message: res.data.message
            })
          }
        })
      },
      addCollect() {
        let params = {
          digest: this.companyDigest
        }
        api.add_collect(params).then(res => {
          if(res.data.result_code==0) {
            this.$message({
              type: 'success',
              message: '收藏成功，还可收藏' + res.data.data.last + '家公司'
            })
            PinganAna.fire(3178)
            this.init()
          } else {
            this.$message({
              type: 'error',
              message: res.data.message
            })
          }
        })
      },
      cancelCollect() {
        let params = {
          digest: this.companyDigest
        }
        api.cancel_collect(params).then(res => {
          if(res.data.result_code==0) {
            this.$message({
              type: 'success',
              message: '取消收藏成功，还可收藏' + res.data.data.last + '家公司'
            })
            this.init()
          } else {
            this.$message({
              type: 'error',
              message: res.data.message
            })
          }
        })
      },
      init() {
        let params = {
          digest: this.companyDigest
        }
        this.buttonDisable = true;
        api.get_ad_basic(params).then(res => {
          if(res.data.result_code==0) {
            if(res.data.data.black_digest) {
              this.disabledTap = true
              return;
            }
            this.company = res.data.data
            this.buttonDisable = false;
            document.title = 'AI竞投-'+this.company.company_name;
            this.getWordData()
            if(this.is_first) {
              this.is_first = false
              let from_name = null
              if(document.referrer != '') {
                from_name = document.referrer.match(/box\/(\S*)/)[1].replace(/([\?][^\?]+)$/,'')
              }
              this.$C.getUserInfo().then((data) => {
                this.$store.state.userInfo = data
                PinganAna.ready(() => {
                  PinganAna.userClickLog(
                    '页面浏览',
                    '{"pageName":"'+this.$route.name+'","页面中文":"'+'AI竞投-'+this.company.company_name+'","pageSource":"'+from_name+'"}',
                    '【AI竞投】系统',
                    this.$store.state.userInfo.vip_type == 5?3:this.$store.state.userInfo.vip_type == 10?1:2,
                    '--',
                    this.$store.state.userInfo.account
                  )
                })
              })
            }
          } else {
            this.$message({
              type: 'error',
              message: res.data.message
            })
          }
        })
      },
      toCompany(digest) {
        PinganAna.fire(3195)
        const { href } = this.$router.resolve({
          name: "companyDetails",
          query: {
            digest
          }
        });
        window.open(href, '_blank');
      },
      reload(digest) {
        this.$router.push('/box/companyDetails?digest=' + digest)
        window.location.reload();
      },
      distinguishExport(count) {
        this.activeName == 'word'?this.handleWordExport(count):this.handleExport(count)
      },
      exportWordList() {
        PinganAna.fire(3185)
        if (this.$store.state.userInfo.vip_type == 10) {
          this.commonDialogText = '您当前为试用版会员，标准版会员可导出数据，请联系您的客户经理开通'
          this.notVipShow = true;
        } else if(this.$store.state.userInfo.vip_type == 5) {
          this.notVipShow = true;
          this.commonDialogText = '当前为基础版会员，标准版会员可导出数据，请联系在线客服开通';
        } else {
          api.get_last_export_cnt().then(res => {
            if(res.data.result_code==0) {
              this.surplus = res.data.data.total-res.data.data.used;
              this.exportCnt = this.wordConfig.real_cnt
              this.dialogVisible = true
            } else {
              this.$message({
                type: 'error',
                message: res.data.message
              })
            }
          })
        }
      },
      handleWordExport(count) {
        let condition = {
          digest: this.companyDigest,
          sort_key: this.wordListSort_key,
          sort_type: this.wordListSort_type,
          page: this.wordConfig.page,
          limit: this.wordConfig.limit,
          b_time: this.datePicker.b_time,
          e_time: this.datePicker.e_time,
        };
        let params = {
          export_type: '5',
          export_limit: count,
          export_condition: JSON.stringify(condition)
        }
        this.dialogVisible = false
        api.ge_export_task(params).then(res => {
          if(res.data.result_code==0) {
            this.$message({
              type: 'success',
              message: '导出任务创建成功！'
            })
            this.dialogVisible = false;
            PinganAna.fire(3186)
            PinganAna.ready(() => {
              PinganAna.userClickLog(
                '数据导出',
                '{"exportType":"企业详情页-推广关键词","exportTitle":"'+this.company.company_name+'","size":"'+count+'"}',
                '【AI竞投】系统',
                this.$store.state.userInfo.vip_type == 5?3:this.$store.state.userInfo.vip_type == 10?1:2,
                '--',
                this.$store.state.userInfo.account
              )
            })
            const { href } = this.$router.resolve({
              name: "export",
            });
            setTimeout(() => {
              window.open(href, '_blank');
            },500)
          } else {
            this.$message({
              type: 'error',
              message: res.data.message
            })
          }
        })
      },
      exportList() {
        PinganAna.fire(3189)
        if (this.$store.state.userInfo.vip_type == 10) {
          this.notVipShow = true;
          this.commonDialogText = '您当前为试用版会员，标准版会员可导出数据，请联系您的客户经理开通'
        } else if(this.$store.state.userInfo.vip_type == 5) {
          this.notVipShow = true;
          this.commonDialogText = '当前为基础版会员，标准版会员可导出数据，请联系在线客服开通';
        } else {
          api.get_last_export_cnt().then(res => {
            if(res.data.result_code==0) {
              this.surplus = res.data.data.total-res.data.data.used;
              this.exportCnt = this.historyConfig.real_cnt
              this.dialogVisible = true
            } else {
              this.$message({
                type: 'error',
                message: res.data.message
              })
            }
          })
        }
      },
      handleExport(count) {
        let condition = {
          digest: this.companyDigest,
          sort: this.historySort,
          page: this.historyConfig.page,
          platform: this.platformSelection.join("_"),
          limit: this.historyConfig.limit,
        };
        let params = {
          export_type: '2',
          export_limit: count,
          export_condition: JSON.stringify(condition)
        }
        this.dialogVisible = false
        api.ge_export_task(params).then(res => {
          if(res.data.result_code==0) {
            this.$message({
              type: 'success',
              message: '导出任务创建成功！'
            })
            this.dialogVisible = false;
            PinganAna.fire(3190)
            PinganAna.ready(() => {
              PinganAna.userClickLog(
                '数据导出',
                '{"exportType":"企业详情页-推广记录","exportTitle":"'+this.company.company_name+'","size":"'+count+'"}',
                '【AI竞投】系统',
                this.$store.state.userInfo.vip_type == 5?3:this.$store.state.userInfo.vip_type == 10?1:2,
                '--',
                this.$store.state.userInfo.account
              )
            })
            const { href } = this.$router.resolve({
              name: "export",
            });
            setTimeout(() => {
              window.open(href, '_blank');
            },500)
          } else {
            this.$message({
              type: 'error',
              message: res.data.message
            })
          }
        })
      },
      getWordData() {
        let params = {
          digest: this.companyDigest,
          sort_key: this.wordListSort_key,
          sort_type: this.wordListSort_type,
          page: this.wordConfig.page,
          limit: this.wordConfig.limit,
          b_time: this.datePicker.b_time,
          e_time: this.datePicker.e_time,
        }
        this.loading5 = true;
        let p = new Promise((resolve, reject) => {
          api.get_ad_word(params).then(res => {
            if(res.data.result_code==0) {
              this.wordList = this.addIndex(res.data.data.rows,this.wordConfig);
              this.loading5 = false;
              this.wordConfig.total = parseInt(res.data.data.cnt)
              this.wordConfig.real_cnt = parseInt(res.data.data.real_cnt)
              if(this.tabIndex != 0) {
                this.wordConfig.real_cnt = res.data.data.real_cnt
              }
            } else {
              this.$message({
                type: 'error',
                message: res.data.message
              })
            }
          })
        })
        return p;
      },
      getInfoData() {
        let params = {
          digest: this.companyDigest,
          sort: this.infoListSort,
          page: this.infoConfig.page,
          limit: this.infoConfig.limit,
        };
        this.loading = true;
        api.get_ad_icp(params).then(res => {
          if (res.status==200) {
            this.loading = false;
          }
          if(res.data.result_code==0) {
            this.infoList = this.addIndex(res.data.data.rows,this.infoConfig);
            this.infoConfig.total = parseInt(res.data.data.cnt)
          }
        })
      },
      getLinkData() {
        let params = {
          digest: this.companyDigest,
          sort_key: this.linkListSort_key,
          sort_type: this.linkListSort_type,
          page: this.linkConfig.page,
          limit: this.linkConfig.limit,
        };
        this.loading2 = true;
        api.get_ad_link(params).then(res => {
          if(res.data.result_code==0) {
            this.linkList = this.addIndex(res.data.data.rows,this.linkConfig);
            this.loading2 = false;
            this.linkConfig.total = parseInt(res.data.data.cnt)
          } else {
            this.$message({
              type: 'error',
              message: res.data.message
            })
          }
        })
      },
      getHistoryData() {
        let params = {
          digest: this.companyDigest,
          keyword: this.historyKeyWord,
          keyword_type: this.history_query_type,
          sort: this.historySort,
          page: this.historyConfig.page,
          platform: this.platformSelection.join("_"),
          limit: this.historyConfig.limit,
        };
        this.loading3 = true;
        api.get_ad_list_by_digest(params).then(res => {
          if(res.data.result_code==0) {
            this.historyList = this.addIndex(res.data.data.rows, this.historyConfig);
            this.loading3 = false;
            this.historyConfig.total = parseInt(res.data.data.cnt)
            this.historyConfig.real_cnt = parseInt(res.data.data.real_cnt)
          } else {
            this.$message({
              type: 'error',
              message: res.data.message
            })
          }
        })
      },
      getCompanyData() {
        let params = {
          digest: this.companyDigest,
          s_area: this.search.s_area,
          sort_key: this.companyListSort_key,
          sort_type: this.companyListSort_type,
          page: this.companyConfig.page,
          limit: this.companyConfig.limit,
        };
        this.loading4 = true;
        api.get_ad_compete_list_v2(params).then(res => {
          if(res.data.result_code==0) {
            this.companyList = this.addIndex(res.data.data.rows,this.companyConfig);
            this.loading4 = false;
            this.companyConfig.total = parseInt(res.data.data.cnt)
            this.companyConfig.real_cnt = parseInt(res.data.data.real_cnt)
          } else {
            this.$message({
              type: 'error',
              message: res.data.message
            })
          }
        })
      }
    }
  }
</script>

<style scoped lang="scss">
    .company_details {
        .hm {
            display: inline-block;
            padding: 5px 9px;
            border-radius: 3px;
            border: solid 1px #ebeff2;
            background-color: #f8fafc;
            font-size: 14px;
            line-height: 14px;
            color: #333333;
            cursor: pointer;
        }
        .rs {
            display: inline-block;
            padding: 5px 9px;
            background-color: #faf4f3;
            border-radius: 3px;
            border: solid 1px #ece4e3;
            font-size: 14px;
            line-height: 14px;
            color: #ef3819;
            cursor: pointer;
        }
        .two_line {
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 2;
            overflow: hidden;
        }
        .three_line {
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 3;
            overflow: hidden;
        }
        .to_ad {
            &:hover {
                color: #1f81f8;
                cursor: pointer;
                text-decoration: underline #1f81f8;
            }
        }
        .new_link {
            color: #1f81f8;
            &:hover {
                cursor: pointer;
                text-decoration: underline #1f81f8;
            }
        }
        .company_info {
            display: flex;
            width: 100%;
            height: 273px;
            background-color: #ffffff;
            box-sizing: border-box;
            border-radius: 5px;
            border: solid 1px #ebeff2;
            .data {
                display: inline-block;
                color: #333333;
                max-width: 750px;
                overflow: hidden;
                text-overflow: ellipsis;
            }
            .left {
                display: flex;
                flex-flow: column;
                align-items: center;
                height: 100%;
                margin-left: 20px;
                .list-left-txt {
                    @extend .font-normal;
                    width: 100px;
                    height: 100px;
                    background-color: #3aa678;
                    opacity: .6;
                    border-radius: 3px;
                    font-size: 25px;
                    letter-spacing: 2px;
                    color: #fff;
                    padding: 14px;
                    box-sizing: border-box;
                    margin-top: 20px;
                    display: flex;
                    justify-content: center;
                    align-items: center;

                    font {
                        text-align: center;
                    }
                }
                .collect {
                    width: 100px;
                    height: 35px;
                    display: flex;
                    align-items: center;
                    margin-top: 15px;
                    div {
                        display: inline-block;
                        color: #333333;
                        margin-left: 7px;
                        position: relative;
                        bottom: 2px;
                    }
                }
                .update {
                    margin-left: 0;
                    margin-top: 20px;
                    width: 100px;
                    height: 35px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    padding: 0;
                    img {
                        transition: .5s ease-in;
                    }
                    div {
                        display: inline-block;
                        color: #333333;
                        margin-left: 7px;
                        position: relative;
                        bottom: 2px;
                    }
                }
                .is-disabled {
                    div {
                        color: #999;
                    }
                }
            }
            .right {
                width: 100%;
                margin-left: 26px;
                position: relative;
                .info_btn {
                    position: absolute;
                    right: 25px;
                    top: 29px;
                }
                .on_monitor {
                    .dialog {
                        width: 105px;
                        height: 68px;
                        background-color: #ffffff;
                        border-radius: 5px;
                        border: solid 1px #ebeff2;
                        position: absolute;
                        right: 20px;
                        top: 65px;
                        padding-top: 5px;
                        padding-bottom: 5px;
                        z-index: 10;
                        .line {
                            font-size: 14px;
                            color: #333333;
                            padding: 9px;
                            cursor: pointer;
                            &:hover {
                                background-color: #e8f2fe;
                                color: #1f81f8;
                            }
                        }
                    }
                }
                .title {
                    margin-top: 24px;
                    font-size: 20px;
                    font-weight: bold;
                    color: #333333;
                    .running {
                        font-size: 12px;
                        font-weight: normal;
                        color: #1f81f8;
                        padding: 3px 6px 4px 6px;
                        margin-left: 12px;
                        border-radius: 5px;
                        background-color:  rgb(31,129,248,0.1);
                    }
                    .close_down {
                        font-size: 12px;
                        font-weight: normal;
                        color: #ef3819;
                        padding: 3px 6px 4px 6px;
                        margin-left: 12px;
                        border-radius: 5px;
                        background-color:  rgb(239,56,25,0.1);
                    }
                }
                .information {
                    font-size: 14px;
                    margin-top: 19px;
                    color: #999999;
                    .information_item {
                        margin-right: 50px;
                    }
                }
                .address {
                    font-size: 14px;
                    margin-top: 16px;
                    color: #999999;
                }
                .extend_box {
                    display: inline-flex;
                    height: 65px;
                    background-color: #f8fafc;
                    border-radius: 5px;
                    border: solid 1px #ebeff2;
                    box-sizing: border-box;
                    margin-top: 17px;
                    position: relative;
                    .extend_item {
                        display: flex;
                        flex-flow: column;
                        justify-content: space-around;
                        color: #999999;
                        padding: 0 20px;
                        font-size: 14px;
                        .icon_date {
                            display: inline-block;
                            margin-right: 5px;
                            width: 14px;
                            height: 14px;
                        }
                    }
                    .blue_data {
                        font-size: 16px;
                        font-weight: bold;
                        color: #1f81f8;
                        display: inline-flex;
                        span {
                            font-size: 14px;
                            font-weight: normal;
                        }
                    }
                    .line {
                        width: 1px;
                        height: 41px;
                        background-color: #ebeff2;

                        margin-top: 12px;
                    }
                }
                .platform_title {
                    font-size: 14px;
                    margin: 17px 0 10px 0;
                    color: #999999;
                }
            }
        }
        .list_info {
            width: 100%;
            /*height: calc(100% - 273px);*/
            background-color: #ffffff;
            margin-top: 10px;
            .title {
                display: flex;
                align-items: center;
                margin-bottom: 17px;
                .xian {
                    display: inline-block;
                    height: 17px;
                    width: 3px;
                    background-color: #1f81f8;
                    margin-right: 13px;
                }
                .text {
                    font-size: 18px;
                    color: #333333;
                    margin-right: 10px;
                }
                .count {
                    font-size: 18px;
                    color: #1f81f8;
                }
            }
            .btn_line {
                padding: 16px 0 8px 0;
                text-align: right;
            }
            .cell_title {
                font-size: 16px;
                color: #1f81f8;
                cursor: pointer;
                text-decoration: underline #1f81f8;
            }
            .cell_text {

            }
            .cell_link {
                height: 25px;
                margin-top: 11px;
                font-size: 14px;
                color: #999;
                white-space: nowrap;
                .link {
                    display: inline-block;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    max-width: var(--max-width);
                }
                .platform {
                    font-size: 12px;
                    color: #7dacfa;
                    padding: 3px 4px;
                    border: 1px solid #7dacfa;
                    margin-left: 13px;
                    position: relative;
                    bottom: 6px;
                }
            }
            .date_picker {
                display: inline-block;
                font-size: 14px;
                color: #999999;
                border: 1px solid #ebeff2;
                padding: 15px;
                border-radius: 10px;
                .picker {
                    color: #333;
                    padding: 5px 15px;
                    margin: 10px;
                    cursor: pointer;
                    box-sizing: border-box;
                }
                .active {
                    color: #1f81f8;
                    border: 1px solid #1f81f8;
                    border-radius: 5px;
                }
            }
            .handle_box {
                margin-bottom: 20px;
                display: flex;
                align-items: flex-end;
                justify-content: space-between;
                color: #999;
                .table_cnt {
                    font-size: 14px;
                    .red_text {
                        color: #ef3819;
                    }
                }
            }
            .select_box {
                border: solid 1px #ebeff2;
                padding: 22px 19px;
                margin-bottom: 19px;
                .cascader-span {
                    color: $c1f81f8;
                }
                .area {
                    .s-l-label {
                        font-size: 14px;
                        color: #999999;
                    }
                    .s-l-box {
                        display: inline-flex;
                        font-size: 14px;
                        color: #333333;
                        cursor: pointer;
                        .checkbox {
                            width: 122px;
                            display: flex;
                            align-items: center;
                            i {
                                width: 14px;
                                height: 14px;
                                background: url('../../../assets/img/search/checkbox.png') center center no-repeat;
                                background-size: 14px;
                                margin-right: 6px;
                            }
                            .myself-hover {
                                display: flex;
                                align-items: center;
                            }
                        }
                        .checkboxed {
                            i {
                                background: url('../../../assets/img/search/checkboxed.png') center center no-repeat;
                                background-size: 14px;
                            }
                        }
                    }
                }
                .select-list {
                    width: 100%;
                    display: flex;
                    box-sizing: border-box;
                    margin-top: 20px;
                    .select-list-label {
                        @extend .font-normal;
                        font-size: 14px;
                        color: #999999;
                        line-height: 26px;
                    }

                    .select-list-box {
                        margin-left: 10px;
                        flex: 1;
                        display: flex;
                        flex-wrap: wrap;

                        span {
                            background: rgba(31, 129, 248, 0.1);
                            padding: 6px 10px;
                            @extend .font-normal;
                            font-size: 14px;
                            color: #1f81f8;
                            margin-right: 10px;
                            border-radius: 3px;
                            display: flex;
                            align-items: center;

                            font {
                                display: inline-block;
                                width: 8px;
                                height: 7px;
                                background: url('../../../assets/img/search/close.png') center center no-repeat;
                                background-size: 8px 7px;
                                margin-left: 8px;
                                cursor: pointer;
                            }
                        }
                    }

                    .select-list-clear {
                        @extend .font-normal;
                        font-size: 14px;
                        color: #999999;
                        margin-right: 18px;
                        background: url('../../../assets/img/search/delete.png') left 6px no-repeat;
                        background-size: 14px;
                        text-indent: 20px;
                        line-height: 26px;
                        width: 80px;
                        cursor: pointer;
                    }
                }
            }
            .history_search {
                border-radius: 3px;
                border: solid 1px #ebeff2;
                padding: 16px 20px;
                font-size: 14px;
                color: #999999;
                .word {
                    .check_box {
                        margin-left: 30px;
                        display: inline-block;
                        .tip {
                            display: flex;
                            align-items: center;
                            img {
                                width: 14px;
                                height: 14px;
                                margin-left: 6px;
                                cursor: pointer;
                            }
                        }
                        .more_info {
                            position: absolute;
                            z-index: 20;
                            .test_triangle_border{
                                width:200px;
                                margin:0 auto 20px;
                                position:relative;
                            }
                            .test_triangle_border a{
                                color:#333;
                                font-weight:bold;
                                text-decoration:none;
                            }
                            .test_triangle_border .popup{
                                width:188px;
                                background:#ffffff;
                                font-size: 14px;
                                line-height: 24px;
                                color: #333333;
                                padding: 13px 9px 13px 9px;
                                position:absolute;
                                left: -8px;
                                top: 10px;
                                box-sizing: border-box;
                                border:1px solid #e8e8e8;
                                border-radius: 6px;
                                box-shadow: 0px 4px 10px 0px rgba(204, 204, 204, 0.75);
                            }
                            .test_triangle_border .popup span{
                                display:block;
                                width:0;
                                height:0;
                                border-width:0 7px 11px;
                                border-style:solid;
                                border-color:transparent transparent #e8e8e8;
                                position:absolute;
                                top:-11px;
                                left: 135px;
                            }
                            .test_triangle_border .popup em{
                                display:block;
                                width:0;
                                height:0;
                                border-width:0 7px 11px;
                                border-style:solid;
                                border-color:transparent transparent #ffffff;
                                position:absolute;
                                top:1px;
                                left:-7px;
                            }
                        }
                    }
                }
                .platform {
                    margin-top: 20px;
                    .platform_selector {
                        display: inline-block;
                    }
                }
            }
        }
        .canClick {
            &:hover {
                color: #1f81f8;
                cursor: pointer;
            }
        }
    }
</style>

<style>

    .company_details .el-table a {
        color: #1f81f8;
    }
    .company_details .list_info .el-tabs__header{
        margin: 0;
        height: 50px;
    }
    .company_details .list_info .el-tabs__nav-wrap {
        height: 50px;
    }
    .company_details .list_info .el-tabs__nav {
        padding-left: 20px;
        height: 50px;
    }
    .company_details .list_info .el-tabs__item {
        padding: 0 10px;
        line-height: 50px;
        font-size: 16px;
    }
    .company_details .list_info .el-tabs__active-bar {
        background-color: #1f81f8;
    }
    .company_details .list_info .el-tabs__item .tab_count{
        font-size: 13px;
        position: relative;
        color: #999999;
    }
    .company_details .list_info .el-tabs__item .new{
        position: absolute;
        top: 3px;
        right: 10px;
    }
    .company_details .list_info .is-active{
        color: #1f81f8;
    }
    .company_details .list_info .is-active .tab_count{
        font-size: 13px;
        color: #1f81f8;
    }
    .company_details .list_info .el-tabs__nav-wrap::after {
        background-color: #ebeff2;
        height: 1px
    }
    .company_details .list_info .el-tabs__active-bar {
        width: 62px;
        left: 20px;
    }
    .company_details .list_info .el-tabs__content {
        padding: 20px;
        min-height: 500px;
    }
    .company_details .list_info .el-table .descending .sort-caret.descending {
        border-top-color: #999999;
    }
    .company_details .list_info .el-table .ascending .sort-caret.ascending {
        border-bottom-color: #999999;
    }
    .company_details .word_table .cell{
        padding: 8px;
    }
</style>

<style lang="scss">
    .company_details .history_search .word .el-input-group__append,
    .el-input-group__prepend {
        padding: 0;
        background-color: #ffffff;
        cursor: pointer;
        &:hover {
            background-color: #e9f3fd;
        }
        &:active {
            background-color: #f6f9fe;
        }
        .box {
            width: 46px;
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }
    .company_details {
        .telephone_dialog {
            width: 435px;
            height: 188px;
            background-color: #ffffff;
            border-radius: 5px;
            border: solid 1px #ebeff2;
            .el-dialog__header {
                padding: 20px;
                font-size: 16px;
                color: #333333;
            }
            .el-dialog__body {
                padding: 27px 20px;
                padding-top: 12px;
                font-size: 14px;
                .line1 {
                    color: #333;
                    margin-bottom: 10px;
                }
                .time {
                    color: #999;
                }
            }
            .el-dialog__footer {
                padding-top: 0;
            }
        }
        .dialog-content {
            .selections {
                padding: 12px 20px;
                border-bottom: 1px solid #ebeff2;
                .selection_item {
                    display: inline-block;
                    font-size: 14px;
                    color: #333;
                    padding: 10px 15px;
                    background-color: #ffffff;
                    border: solid 1px #ebeff2;
                    margin-right: 11px;
                    border-radius: 3px;
                    cursor: pointer;
                    span {
                        color: #999999;
                    }
                }
                .active {
                    color: #1f81f8;
                    border: solid 1px #1f81f8;
                    span {
                        color: #1f81f8;
                    }
                }
            }
            .tel_list {
                padding: 0 20px;
                max-height: 308px;
                overflow-y: scroll;
                .list-item {
                    padding: 9px;
                    font-size: 14px;
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    position: relative;
                    border-bottom: 1px solid #ebeff2;
                    .height {
                        position: absolute;
                        left: 3px;
                        top: 2px;
                    }
                    .number {
                        color: #1f81f8;
                        margin-left: 9px;
                    }
                    .text {
                        font-size: 12px;
                        color: #999999;
                    }
                    .percent {
                        color: #fe534c;
                        margin-left: 13px;
                    }
                }
            }
            .footer_text {
                padding: 20px 31px;
                color: #999999;
                font-size: 12px;
            }
        }
        .history_drag_dialog {
            margin: 10px 0;
            th,td {
                font-size: 14px;
                color: #333333;
                border: 1px solid #ebeff2;
                padding: 9px 11px;
                text-align: left;
            }
            #dialogChart {
                width: 338px;
                height: 186px;
            }
        }
    }
</style>
