<template>
    <div class="drag_dialog" v-if="value" v-drag>
        <slot name="head">
            <div class="dialog_title">
                <span>{{title}}</span>
                <span class="right">
                    <div class="drag">
                        <img src="@/assets/img/companyDetails/drag_icon.png" alt="" width="14" height="14">
                        <span>按住拖动</span>
                    </div>
                    <img class="close" @click="closeDialog" src="@/assets/img/icon/close.png" alt=""width="14" height="14">
                </span>
            </div>
        </slot>
        <div class="list_contain scrollbar-class">
            <slot name="body" ></slot>
        </div>
        <div class="dialog_footer"><slot name="footer"></slot></div>
    </div>
</template>

<script>
  export default {
    name: "drag_dialog",
    props: ['title', 'value'],
    methods: {
      closeDialog() {
        this.$emit('input', false)
      }
    }
  }
</script>

<style scoped lang="scss">
.drag_dialog {
        width: 435px;
        background: #FFFFFF;
        position: fixed;
        top: 25%;
        left: 35%;
        z-index: 200;
        border-radius: 5px;
        border: solid 1px #ebeff2;
        box-shadow: 0 2px 12px 0 rgb(0 0 0 / 10%);
        .dialog_title {
            background-color: #f8fafc;
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 20px;
            font-size: 16px;
            border-bottom: 1px solid #ebeff2;
            .right {
                .drag {
                    display: inline-block;
                    font-size: 14px;
                    color: #999;
                    cursor: all-scroll;
                    img {
                        position: relative;
                        top: 2px;
                        margin-right: 5px;
                    }
                }
                .close {
                    cursor: pointer;
                    margin-left: 25px;
                }
            }
        }
        .list_contain {
            padding: 10px 20px;
            max-height: 422px;
            overflow-y: scroll;
        }
        .dialog_footer {
            text-align: right;
            border-top: 1px solid #ebeff2;
            padding: 11px 16px;
        }
    }
</style>
