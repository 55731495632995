<template>
    <div class="adtime_main">
        <div class="no_data" v-if="dataLoading">
            <img class="empty_img" src="../../../assets/img/compare/searching.png" height="144" width="187"/>
            <span>数据生成中</span>
        </div>
        <div class="no_data" v-if="noData && !dataLoading">
            <img class="empty_img" src="../../../assets/img/compare/empty.png" height="144" width="187"/>
            <span>暂无数据</span>
        </div>
        <div class="has_data" v-if="!noData">
            <div class="tab_title">
                <div>广告推广时段分布</div>
                <div class="sub">发现广告数表示在时间段内使用推广关键词模拟人工搜索发现的广告数。AI竞投24小时不间断采集广告，但有一定的不确定性。</div>
            </div>
            <div class="report_item" v-for="(value, key, index) in platformData" :key="index">
                <div class="report_title">
                    <div class="platform">
                        <img v-if="key == '百度PC'" src="@/assets/img/companyDetails/baidu_search.png" alt="">
                        <img v-if="key == '百度移动'" src="@/assets/img/companyDetails/baidu_mobile.png" alt="">
                        <img v-if="key == '360PC'" src="@/assets/img/companyDetails/360_search.png" alt="">
                        <img v-if="key == '360移动'" src="@/assets/img/companyDetails/360_mobile.png" alt="">
                        <img v-if="key == '搜狗PC'" src="@/assets/img/companyDetails/sougou_search.png" alt="">
                        <img v-if="key == '搜狗移动'" src="@/assets/img/companyDetails/sougou_mobile.png" alt="">
                        <img v-if="key == '神马搜索'" src="@/assets/img/companyDetails/shenma_search.png" alt="">
                        <span>{{key}}</span>
                    </div>
                    <div class="time">
                        <span>分析日期范围：</span>
                        <span>{{platformData[key].time.start}}</span>~
                        <span>{{platformData[key].time.end}}</span>
                    </div>
                </div>
                <div class="report_info">
                    <div class="barChart" :id="'barChart'+index"></div>
                    <div class="week">
                        <table border="1">
                            <tr class="head">
                                <th :class="platformData[key].week[0] == '1'?'has_ad':''">{{platformData[key].week[0] == '1'?'有广告':'无广告'}}</th>
                                <th :class="platformData[key].week[1] == '1'?'has_ad':''">{{platformData[key].week[1] == '1'?'有广告':'无广告'}}</th>
                                <th :class="platformData[key].week[2] == '1'?'has_ad':''">{{platformData[key].week[2] == '1'?'有广告':'无广告'}}</th>
                                <th :class="platformData[key].week[3] == '1'?'has_ad':''">{{platformData[key].week[3] == '1'?'有广告':'无广告'}}</th>
                                <th :class="platformData[key].week[4] == '1'?'has_ad':''">{{platformData[key].week[4] == '1'?'有广告':'无广告'}}</th>
                                <th :class="platformData[key].week[5] == '1'?'has_ad':''">{{platformData[key].week[5] == '1'?'有广告':'无广告'}}</th>
                                <th :class="platformData[key].week[6] == '1'?'has_ad':''">{{platformData[key].week[6] == '1'?'有广告':'无广告'}}</th>
                            </tr>
                            <tr>
                                <td>周一</td>
                                <td>周二</td>
                                <td>周三</td>
                                <td>周四</td>
                                <td>周五</td>
                                <td>周六</td>
                                <td>周日</td>
                            </tr>
                        </table>
                        <div class="text">广告采集星期分布</div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
  import * as api from "@/api/companyDetails"
  import PinganAna from "pingansec-vue-ana";
  import * as echarts from 'echarts';
  export default {
    name: "adTime",
    data() {
      return {
        noData: true,
        dataLoading: true,
        platformData: {},
        timer: null
      }
    },
    methods: {
      drawBar(dom, key) {
        let myChart = echarts.init(document.getElementById(dom))
        let dataAxis = [];
        for(var i = 0; i <24; i++) {
          dataAxis.push(i + ':00')
        }
        myChart.setOption({
          tooltip:{
            borderColor: '#ebeff2',
            formatter: (params, ticket, callback) => {
              let e_time = params.name.slice(0, -2) + '59'
              return params.name + '~' + e_time +'<br>'+ params.seriesName+'：' +params.value
            }
          },
          legend: {
            left: 15,
            top: 15,
            itemHeight: 24,
            itemWidth: 24,
            itemStyle: {
              borderColor: "#fff",
              borderWidth: 10,
            },
            textStyle: {
              fontSize: 14,
            }
          },
          grid: {
            right: 0
          },
          xAxis: {
            data: dataAxis,
            nameLocation: 'middle',
            nameGap: 40,
            name: '广告采集24小时分布',
            nameTextStyle: {
              fontSize: 14,
              color: '#333',
            },
            axisTick: {
              show: false
            },
            axisLine: {
              show: false
            },
            axisLabel: {
              color: '#c0c5cb'
            },
            z: 10
          },
          yAxis: {
            axisLine: {
              show: false
            },
            axisTick: {
              show: false
            },
            axisLabel: {
              color: '#c0c5cb'
            }
          },
          dataZoom: [
            {type: 'inside'}
          ],
          series: [
            {
              type: 'bar',
              name: '发现广告数',
              barMaxWidth: 10,
              itemStyle: {
                color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                  { offset: 1, color: '#1f81f8' }
                ])
              },
              emphasis: {
                itemStyle: {
                  color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                    { offset: 1, color: '#1f81f8' },
                  ])
                }
              },
              data: this.platformData[key].day
            }
          ]
        });
        window.addEventListener("resize", () => { myChart.resize(); });
      },
      init() {
        let params = {
          digest: this.$route.query.digest
        }
        let p = new Promise((resolve, reject) => {
          api.analysis(params).then(res => {
            if(res.data.result_code == 0) {
              this.dataLoading = false;
              if(!(res.data.data instanceof Array)) {
                this.noData = false
                this.platformData = res.data.data
                let keys = Object.keys(this.platformData)
                for(var i = 0; i < keys.length; i++) {
                  let dom = 'barChart' + i, key = keys[i]
                  this.$nextTick(() => {
                    this.drawBar(dom, key)
                  })
                }
              }
              resolve(res.data.data);
            } else {
              this.$message({
                type: 'error',
                message: res.data.message
              })
            }
          })
        })
        return p;
      }
    }
  }
</script>

<style scoped lang="scss">
.adtime_main {
    width: 100%;
    .no_data {
        display: flex;
        flex-direction: column;
        align-items: center;
        font-size: 16px;
        color: #333333;
        padding: 50px ;
        .sub {
            color: #999999;
            margin-top: 6px;
            margin-bottom: 26px;
        }
    }
    .has_data {
        width: 100%;
        .tab_title {
            font-size: 16px;
            color: #333333;
            margin-top: 5px;
            .sub {
                font-size: 13px;
                color: #999999;
                margin-top: 10px;
                margin-bottom: 21px;
            }
        }
        .report_item {
            width: 100%;
            border-radius: 3px;
            border: solid 1px #ebeff2;
            margin-bottom: 15px;
            .report_title {
                padding: 18px 13px 15px 13px;
                border-bottom: solid 1px #ebeff2;
                .platform {
                    display: flex;
                    align-items: center;
                    color: #333333;
                    font-size: 14px;
                    img {
                        width: 13px;
                        margin-right: 5px;
                    }
                }
                .time {
                    font-size: 13px;
                    color: #999999;
                    margin-top: 10px;
                }
            }
            .report_info {
                width: 100%;
                display: flex;
                align-items: flex-end;
                .barChart {
                    width: 55%;
                    height: 366px;
                }
                .week {
                    width: 45%;
                    text-align: center;
                    table {
                        margin: 0 auto;
                        font-size: 12px;
                        color: #333333;
                        margin-bottom: 27px;
                        th, td {
                            padding: 12px 10px;
                            border: solid 1px #ebeff2;
                        }
                        .head {
                            background-color: #cde4ff;
                            color: #fff;
                        }
                        .has_ad {
                            background-color: #1f81f8;
                        }
                    }
                    .text {
                        font-size: 14px;
                        color: #333333;
                        margin-bottom: 22px;
                    }
                }
            }
        }
    }
}
</style>
