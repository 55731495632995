import http from '@/utils/http'

// 获取上部基础信息
export function get_ad_basic(data) {
    return http({
        url: 'u-adsearch/ac-get_ad_basic',
        method: 'get',
        params: data
    })
}
// 获取网站信息列表
export function get_ad_icp(data) {
    return http({
        url: 'u-adsearch/ac-get_ad_icp',
        method: 'get',
        params: data
    })
}

// 获取关键词列表
export function get_ad_word(data) {
    return http({
        url: '/u-adsearch/ac-get_ad_word',
        method: 'get',
        params: data
    })
}

// 获取推广链接列表
export function get_ad_link(data) {
    return http({
        url: '/u-adsearch/ac-get_ad_link',
        method: 'get',
        params: data
    })
}

// 获取推广记录列表
export function get_ad_list_by_digest(data) {
    return http({
        url: '/u-adsearch/ac-get_ad_list_by_digest',
        method: 'get',
        params: data
    })
}


//按公司找竞品（地区）
export function get_ad_compete_list_v2(data) {
    return http({
        url: '/u-adsearch/ac-get_ad_compete_list_v2',
        transformRequest: [(data) => {
            return data
        }],
        header: {
            'Content-Type': 'application/json'
        },
        method: 'post',
        data: JSON.stringify(data)
    })
}

// 监测关键词
export function add_monitor(data) {
    return http({
        url: '/u-base/ac-add_monitor',
        method: 'get',
        params: data
    })
}

// 取消监测关键词
export function cancel_monitor(data) {
    return http({
        url: '/u-base/ac-cancel_monitor',
        method: 'get',
        params: data
    })
}

// 加入收藏
export function add_collect(data) {
    return http({
        url: '/u-base/ac-add_collect',
        method: 'get',
        params: data
    })
}

// 取消收藏
export function cancel_collect(data) {
    return http({
        url: '/u-base/ac-cancel_collect',
        method: 'get',
        params: data
    })
}

//导出数据
export function ge_export_task(data) {
    return http({
        url: '/u-base/ac-ge_export_task',
        transformRequest: [(data) => {
            return data
        }],
        header: {
            'Content-Type': 'application/json'
        },
        method: 'post',
        data: JSON.stringify(data)
    })
}

//可导出数据查询
export function get_last_export_cnt(data) {
    return http({
        url: '/u-base/ac-get_last_export_cnt',
        method: 'get',
        params: data
    })
}

//创建刷新任务
export function ge_refresh_task(data) {
    return http({
        url:'/u-base/ac-ge_refresh_task',
        method: 'get',
        params: data
    })
}

//关键词推荐地域列表
export function get_word_area_reports_list(data) {
    return http({
        url:'/u-adsearch/ac-get_word_area_reports_list',
        method: 'get',
        params: data
    })
}

//关键词 推广地域分析
export function post_word_area_reports(data) {
    return http({
        url: '/u-adsearch/ac-post_word_area_reports',
        transformRequest: [(data) => {
            return data
        }],
        header: {
            'Content-Type': 'application/json'
        },
        method: 'post',
        data: JSON.stringify(data)
    })
}


//关键词地域列表
export function get_more_word_for_area(data) {
    return http({
        url:'/u-adsearch/ac-get_more_word_for_area',
        method: 'get',
        params: data
    })
}

//推广落地页列表
export function get_landing_list(data) {
    return http({
        url:'/u-landpage/ac-list',
        method: 'get',
        params: data
    })
}

//推广时段列表
export function analysis(data) {
    return http({
        url:'/u-pro-period/ac-analysis',
        method: 'get',
        params: data
    })
}

//找企业联系方式
export function get_link_list_by_digest(data) {
    return http({
        url: '/u-search/ac-get_link_list_by_digest',
        transformRequest: [(data) => {
            return data
        }],
        header: {
            'Content-Type': 'application/json'
        },
        method: 'post',
        data: JSON.stringify(data)
    })
}
