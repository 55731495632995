<template>
    <div class="platform_box">
        <span v-if="!list.length" class="empty_item">
            <img src="@/assets/img/companyDetails/icon_exclamation.png" alt="">
            <span>未发现该企业广告推广信息</span>
        </span>
        <span v-if="showList[0]" class="platform_item" @click="clickItem(1)">
            <img src="@/assets/img/companyDetails/baidu_search.png" alt="">
            <span>百度PC</span>
        </span>
        <span v-if="showList[1]" class="platform_item" @click="clickItem(2)">
            <img src="@/assets/img/companyDetails/baidu_mobile.png" alt="">
            <span>百度移动</span>
        </span>
        <span v-if="showList[2]" class="platform_item" @click="clickItem(3)">
            <img src="@/assets/img/companyDetails/360_search.png" alt="">
            <span>360PC</span>
        </span>
        <span v-if="showList[3]" class="platform_item" @click="clickItem(4)">
            <img src="@/assets/img/companyDetails/360_mobile.png" alt="">
            <span>360移动</span>
        </span>
        <span v-if="showList[4]" class="platform_item" @click="clickItem(5)">
            <img src="@/assets/img/companyDetails/sougou_search.png" alt="">
            <span>搜狗PC</span>
        </span>
        <span v-if="showList[5]" class="platform_item" @click="clickItem(6)">
            <img src="@/assets/img/companyDetails/sougou_mobile.png" alt="">
            <span>搜狗移动</span>
        </span>
        <span v-if="showList[6]" class="platform_item" @click="clickItem(7)">
            <img src="@/assets/img/companyDetails/shenma_search.png" alt="">
            <span>神马搜索</span>
        </span>
    </div>
</template>

<script>
  export default {
    name: "platformBox",
    props: ['list'],
    data() {
      return {
        showList: [false, false, false, false, false, false, false]
      }
    },
    watch: {
      list(newList, oldList) {
        this.showDom(newList)
      }
    },
    methods: {
      showDom(list){
        for (var i = 0; i < list.length; i++) {
          this.showList[list[i]-1] = true
        }
      },
      clickItem(index) {
        this.$emit('childClick', index);
      }
    }
  }
</script>

<style scoped lang="scss">
    .platform_box {
        .empty_item {
            display: inline-flex;
            align-items: center;
            margin-top: 5px;
            img {
                width: 14px;
                height: 14px;
            }
            span {
                margin-left: 7px;
                font-size: 12px;
                color: #999999;
            }
        }
        .platform_item {
            padding: 4px 6px;
            border-radius: 5px;
            background-color: #f8fafc;
            font-size: 12px;
            color: #666666;
            margin-right: 10px;
            display: inline-flex;
            align-items: center;
            cursor: pointer;
            img {
                width: 14px;
                height: 14px;
                margin-right: 5px;
            }
        }
    }
</style>